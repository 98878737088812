import React, {useState} from 'react'
import { RegisterContainer, RegisterWrapper, RegisterSection, RegisterTopline, RegisterForm, RegisterFormInput } from './RegisterElements'
import axios from 'axios'
import makeToast from '../../pages/Toaster'
import {useNavigate} from 'react-router-dom'

const Register = ({numberUsers, setNumerUsers}) => {

  const navigate = useNavigate()

  const [inputs, setInputs] = useState({})

  const handleInputChange = (e) => {
    setInputs(prevState => ({...prevState, [e.target.id]: e.target.value}))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    axios.post('api/v1/auth/register', {
      name: inputs.username,
      email: inputs.email,
      password: inputs.password
    })
    .then(response => {
      localStorage.setItem('accessToken', response.data.token)
      const nU = numberUsers;
      setNumerUsers(nU+1)
      makeToast('success', response.data.msg);
    })
    .then(response => {
      navigate('/')
    })
    .catch(error => {
      makeToast('error', error.message);
    })
  }

  return (
    <>
      <RegisterContainer>
        <RegisterWrapper>
          <RegisterTopline>Registrera</RegisterTopline>
          <RegisterSection>
            <RegisterForm onSubmit={handleSubmit}>
              <RegisterFormInput>
                <label htmlFor='username'>Namn</label>
                <input type='text' name='username' id='username' autoComplete='on' onChange={handleInputChange} />
              </RegisterFormInput>
              <RegisterFormInput>
                <label htmlFor='email'>Namn</label>
                <input type='email' name='email' id='email' autoComplete='on' placeholder='namn@example.com' onChange={handleInputChange} />
              </RegisterFormInput>
              <RegisterFormInput>
                <label htmlFor='new-password'>Lösenord</label>
                <input type='password' name='new-password' id='password' onChange={handleInputChange} />
              </RegisterFormInput>
              <RegisterFormInput>
                <input type='submit' />
              </RegisterFormInput>
            </RegisterForm>
          </RegisterSection>
        </RegisterWrapper>
      </RegisterContainer>
    </>
  )
}

export default Register
