import React from "react";
import {ModalMainLanSpec, ModalMainCommon } from './AddModalElements'

const ModalEducationCourseSubPart = ({cid, scid, inputHandler }) => {

  return (
    <>
    <ModalMainLanSpec>
    <p>Sub-Part {cid+1}:{scid+1}</p>
      <table>
        <thead>
          <tr>
            <th>Property</th><th>Content</th><th>Egenskap</th><th>Innehåll</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <label htmlFor={'subcoursename' + cid}>Sub Course name:</label>
            </td>
            <td>
              <input title='' type='text' id={'subcoursename' + cid} name={`course[${cid}].subparts[${scid}].name.en`} onChange={(e) => inputHandler(e)} />
            </td>
            <td>
              <label htmlFor={'subcoursenamesv' + cid}>Kursnamn:</label>
            </td>
            <td>
              <input title='' type='text' id={'subcoursenamesv' + cid} name={`course[${cid}].subparts[${scid}].name.sv`} onChange={(e) => inputHandler(e)} />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor={'subdescription' + cid}>Description:</label>
            </td>
            <td>
              <input title='' type='text' id={'subdescription' + cid} name={`course[${cid}].subparts[${scid}].description.en`} onChange={(e) => inputHandler(e)} />
            </td>
            <td>
              <label htmlFor={'subdescriptionsv' + cid}>Beskrivning:</label>
            </td>
            <td>
              <input title='' type='' id={'subdescriptionsv' + cid} name={`course[${cid}].subparts[${scid}].description.sv`} onChange={(e) => inputHandler(e)} />
            </td>
          </tr>
        </tbody>
      </table>
    </ModalMainLanSpec>
      <ModalMainCommon>
        <label htmlFor={'subcoursecode' + cid}>Sub Course Code:</label>
        <input type='text' id={'subcoursecode' + cid} name={`course[${cid}].subparts[${scid}].code`} onChange={(e) => inputHandler(e)} />
        <label htmlFor={'subcoursecredits' + cid}>Sub Course Credits:</label>
        <input type='text' id={'coursecredits' + cid} name={`course[${cid}].subparts[${scid}].credits`} onChange={(e) => inputHandler(e)} />
        <label htmlFor={'subcoursegrade' + cid}>Sub Course Grade:</label>
        <input type='text' id={'subcoursegrade' + cid} name={`course[${cid}].subparts[${scid}].grade`} onChange={(e) => inputHandler(e)} />
        <label htmlFor={'subcoursedate' + cid}>Sub Course Finish Date:</label>
        <input type='date' id={'subcoursedate' + cid} name={`course[${cid}].subparts[${scid}].date`} onChange={(e) => inputHandler(e)} />
      </ModalMainCommon>
    </>
  )
}

export default ModalEducationCourseSubPart
