import React from 'react'
import InfoModal from '../InfoModal'
import { useState } from 'react';
import {FaEdit} from 'react-icons/fa'
import EditModal from '../EditModal/';
import Modal from "react-bootstrap/Modal";
import { CommonContainer, CommonDates, CommonDescription, CommonEdit, CommonLeft, CommonLocation, CommonMiddle, CommonPlace, CommonRole, CommonStartDate, CommonWrapper, CommonMarginFiller } from '../CommonElements/CommonElements';

const Certificates = ({id, data, certificates, setCertificates, isLoggedIn, l, lan}) => {

  const name= data.name[lan]
  const date = new Date(data.date.split('-'));
  const issuer = data.issuer
  const description = data.description[lan]
  const location = data.location;

  const [modal, setModal] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const openModal = () => {
    setModal(true);
  }

  const closeModal = () => {
    setModal(false)
  }


  return (
    <>
      <CommonContainer
    onMouseEnter={() => isLoggedIn ? setShowEdit(true) : setShowEdit(false)}
    onMouseLeave={() => setShowEdit(false)}
    >
        <CommonWrapper>
          <CommonLeft onClick={() => {
            setInfoModal(true)
            setEditModal(false)
            openModal()
          }}>
            <CommonRole>{name}</CommonRole>
            <CommonPlace>{issuer}</CommonPlace>
            <CommonLocation>{location.split(' ').slice(1)}</CommonLocation>
            <CommonDates>
              <CommonStartDate>
                { l && (
                  date.toLocaleString('en-EN', {month: 'short', year:'numeric'})
                )}
                { !l && (
                  date.toLocaleString('sv-SE', {month: 'short', year:'numeric'})
                )}
              </CommonStartDate>
            </CommonDates>
            {!description && (
              <CommonMarginFiller/>
            )}
          </CommonLeft>
          {description && (
            <CommonMiddle onClick={() => {
              setInfoModal(true);
              setEditModal(false);
              openModal();
            }}>
            <CommonDescription>{description}</CommonDescription>
            </CommonMiddle>
          )}
          {showEdit && (
            <CommonEdit>
              <FaEdit onClick={() => {
                setEditModal(true);
                setInfoModal(false)
                openModal();
              }}/>
            </CommonEdit>
          )}
        </CommonWrapper>
        <Modal
          size="lg"
          show={modal}
          onHide={() => setModal(false)}
        >
          {editModal && (
            <EditModal 
            type='certificate' 
            d={data} 
            id={id} 
            data={certificates} 
            setCertificates={setCertificates} 
            openModal={openModal}
            closeModal={closeModal}
            />
          )}
          {infoModal && (
            <InfoModal type='certificate' d={data} topline={data.name[lan]} l={l} lan={lan} />
          )}
        </Modal>
      </CommonContainer>
    </>
  )
}

export default Certificates
