import React from "react";
import {
  NavLan,
  NavbarContainer,
  NavbarItem,
  NavBarPrintContainer,
  Flag,
} from "./NavbarElements";
import { IoLogInOutline, IoLogOutOutline } from "react-icons/io5";
import { MdOutlineSpaceDashboard } from "react-icons/md";
import { GB, SE } from "country-flag-icons/react/3x2";
import ReactCountryFlag from "react-country-flag";

const Navbar = ({ isLoggedIn, numberUsers, lan, setLan, l, setL }) => {
  const changeLan = () => {
    if (lan === "en") {
      setLan("sv");
      setL(false);
    } else {
      setLan("en");
      setL(true);
    }
  };

  return (
    <>
      <NavbarContainer>
        <NavbarItem to="/">
          <MdOutlineSpaceDashboard title="Dashboard" />
        </NavbarItem>
        {isLoggedIn ? (
          <NavbarItem to="/logout">
            <IoLogOutOutline title="Logout" />
          </NavbarItem>
        ) : (
          <NavbarItem to="/login">
            <IoLogInOutline title="Login" />
          </NavbarItem>
        )}
        {isLoggedIn ? undefined : numberUsers < 1 ? (
          <NavbarItem to="/register">
            {l ? "Register" : "Registrera"}
          </NavbarItem>
        ) : undefined}
        <NavLan onClick={changeLan}>
          {l ? (
            <ReactCountryFlag countryCode="SE" title="Byt till Svenska" svg />
          ) : (
            <ReactCountryFlag countryCode="GB" title="Change to English" svg />
          )}
        </NavLan>
        {/*<NavBarPrintContainer>
        {l ? 'Philip Tunbjer - Mecanical Engineer, M.sc.': 'Philip Tunbjer - Civilingenjör, M.sc.'}
        </NavBarPrintContainer>*/}
      </NavbarContainer>
    </>
  );
};

export default Navbar;
