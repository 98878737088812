import React, { useEffect, useState } from "react";
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import axios from "axios";
import makeToast from "../../pages/Toaster";

import '../../styles/common.css'
import Dashboard from "../Dashboard";
import Login from "../Login"
import Register from "../Register"
import ErrorPage from "../Error";
import Navbar from "../Navbar";
import Logout from "../Logout";

function App() {

  const [numberUsers, setNumberUsers] = useState()

  const [isLoggedIn, setIsLoggedIn] = useState(false)

  const [token, setToken] = useState(null)

  const [lan, setLan] = useState('en');

  const [l, setL] = useState(true)

  document.documentElement.lang = lan;

  useEffect(() => {
    setToken(localStorage.getItem('accessToken'))
    if (token) {
      logIn()
    }

    const nU = async () => {
      await axios.get('/api/v1/auth')
        .then(response => {
          setNumberUsers(response.data.numberofusers)
        })
        .catch(err => {
          console.log(err)
          makeToast('error', err.data.msg)
        });
    }
    nU();
  }, [token])

  const logIn = () => {
    setIsLoggedIn(true);
  }

  const logOut = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('accessToken')
    setToken(null)
  }

  return (
    <Router>
      <Navbar isLoggedIn={isLoggedIn} numberUsers={numberUsers} lan={lan} setLan={setLan} l={l} setL={setL} />
      <Routes>
        <Route path='/' element={<Dashboard isLoggedIn={isLoggedIn} lan={lan} l={l}/>} />
        <Route path='/logout' element={<Logout logOut={logOut} lan={lan} l={l} />} />
        <Route path='/login' element={<Login logIn={logIn} setToken={setToken} lan={lan} l={l} />} />
        {numberUsers < 1 &&
          <Route path='/register' element={<Register setNumberUsers={setNumberUsers} numberUsers={numberUsers} lan={lan} l={l}/>} />
        }

        <Route path='*' element={<ErrorPage />} />
      </Routes>
    </Router>
  )
}

export default App;
