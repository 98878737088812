import React from "react";
import { useNavigate } from "react-router-dom";
import {
  LogoutContainer,
  LogoutTopLine,
  LogoutSection,
  LogoutForm,
  LogoutFormInput,
} from "./LogoutElements";

const Logout = ({ logOut, l }) => {
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    logOut();
    localStorage.removeItem("accessToken");
    navigate("/");
  };

  return (
    <>
      <LogoutContainer>
        <LogoutTopLine>{l ? "LOGOUT" : "LOGGA UT"}</LogoutTopLine>
        <LogoutSection>
          <LogoutForm onSubmit={handleSubmit}>
            <LogoutFormInput>
              <button type="submit">{l ? "Logout" : "Logga ut"}</button>
            </LogoutFormInput>
          </LogoutForm>
        </LogoutSection>
      </LogoutContainer>
    </>
  );
};

export default Logout;
