import styled from 'styled-components'
import {COLOR_BRIGHT_FONT, COLOR_DARK_FONT, COLOR_DARK_MAIN, COLOR_BRIGHT_MAIN, COLOR_DARK_SECONDARY, COLOR_BRIGHT_SECONDARY, FONT_STYLE, FONTSIZE_1, MAIN_OFFSET, MAIN_MID_OFFSET, MID_MAIN_OFFSET, MINOR_OFFSET, FONTSIZE_3, FONT_REM_1, FONT_REM_3, FONT_REM_4, FONT_REM_2, SCREEN_MAX_WIDTH} from '../../styles/variables'

export const CommonContainer = styled.div`
  cursor: pointer;

  @media screen and (max-width: ${SCREEN_MAX_WIDTH}){
    font-size: ${FONTSIZE_3};
    max-width: 100vw;
  }

  @media print{
    break-before: auto;
    display: block;
  }
`

export const CommonWrapper = styled.div`
  padding: ${MID_MAIN_OFFSET} 1px;
  display: grid;
  grid-template-columns: 2fr 4fr 0.2fr;
  border-bottom: 1px solid ${COLOR_BRIGHT_SECONDARY};
  
  @media screen and (max-width: ${SCREEN_MAX_WIDTH}){
    width: 90%;
    margin: auto 50%;
    transform: translateX(-50%);
    padding: 1px 1px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:  "top"
                          "right"
                          "left";
    grid-gap: 1px;
  }
`

export const CommonLeft = styled.div`
  padding: 0px ${MINOR_OFFSET};
  text-align: left;

  @media screen and (max-width: ${SCREEN_MAX_WIDTH}){
    padding: 0px 1px;
    margin: ${MAIN_MID_OFFSET} 0px 0px 0px;
    grid-area: top;
    text-align: center;
  }
  
  @media print{
    display: inline;
  }
`

export const CommonMiddle = styled.div`
  padding: 0px ${MINOR_OFFSET};
  text-align: left;

  @media screen and (max-width: ${SCREEN_MAX_WIDTH}){
    padding: 0px 1px;
    margin: ${MAIN_MID_OFFSET} 0px ${MAIN_MID_OFFSET} 0px;
    grid-area: left;
  }
  
  @media print{
    display: inline;
  }
`

export const CommonRight = styled.div`
  padding: 0px ${MINOR_OFFSET};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: ${SCREEN_MAX_WIDTH}){
    padding: 0px 1px;
    grid-area: right;
  }
  
  @media print{
    display: inline;
  }
`

export const CommonEdit = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and(max-width: ${SCREEN_MAX_WIDTH}){
    display: none;
  }
`

export const CommonPlace = styled.div`
  padding: 0px 0px;
  font-size: ${FONT_REM_1};

  @media screen and (max-width: ${SCREEN_MAX_WIDTH}){
    font-size: ${FONT_REM_3};
  }
  
  @media print{
    display: inline;
  }
`

export const CommonRole = styled.div`
  font-size: ${FONT_REM_4};

  @media screen and (max-width:${SCREEN_MAX_WIDTH}){
    margin: 0px 0px ${MAIN_MID_OFFSET} 0px;
  }

  @media print{
    display: inline;
  }
`

export const CommonDescription = styled.p`
  text-align: justify;
  font-size: ${FONT_REM_2};
  padding: 0px ${MINOR_OFFSET};
  margin-bottom: 0;
  hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;

  @media screen and (max-width: ${SCREEN_MAX_WIDTH}){
    padding: 0px;
  }
  
  @media print{
    display: inline;
  }
`

export const CommonDates = styled.div`
  font-style: italic;
  padding: 0px 0px;
  display: block;
  margin: 0;

  @media screen and (max-width:${SCREEN_MAX_WIDTH}){
    display: inline;
  }
  
  @media print{
    display: inline;
  }
`

export const CommonStartDate = styled.span`
  font-style: italic;
  padding: 0px 0px;
  font-size: ${FONT_REM_1};
  margin-right: 2px;
`

export const CommonEndDate = styled.span`
  padding: 0px 0px;
  font-size: ${FONT_REM_1};
  margin-left: 2px;
`

export const CommonDuration = styled.div`
  padding: 0px 0px;
  font-size: ${FONT_REM_1};
  margin-left: 2px;
`

export const CommonLocation= styled.p`
  justify-content: left;
  margin: 0;
  font-size: ${FONT_REM_1};

  @media screen and (max-width:${SCREEN_MAX_WIDTH}){
  }
  
  @media print{
    display: inline;
  }
`

export const CommonMarginFiller = styled.div`
  @media screen and (max-width:${SCREEN_MAX_WIDTH}){
    margin-bottom: ${MAIN_MID_OFFSET};
  }
`

export const CommonDistance = styled.div`
  margin-top: 2rem;
`

export const CommonMidTitle = styled.h3`
  font-size: ${FONT_REM_2};
  padding: 0px ${MINOR_OFFSET};
  margin: 0;

  @media screen and (max-width: ${SCREEN_MAX_WIDTH}){
    padding: 0px;
    text-align: center;
    font-size: ${FONT_REM_3};
  }
  
  @media print{
    display: inline;
  }
`

export const CommonList = styled.div`
  padding: 0px ${MINOR_OFFSET};

  ul > li {
    margin: 0 0px;
    font-size: ${FONT_REM_2};
  }
  ul {
    padding: 0;
    list-style-type: none;
  }

  @media screen and (max-width: 1048px){
    text-align: center;
     ul > li {
      margin: 0px 0px;
      font-size: ${FONT_REM_3};
     }
  }
  
  @media print{
    display: inline;
  }
`
