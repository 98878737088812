import React from 'react'
import InfoModalAssignments from './InfoModalAssignments'
import InfoModalEmployments from './InfoModalEmployments'
import InfoModalEducations from './InfoModalEducations'
import InfoModalCertificates from './InfoModalCertificates'
import { ModalContainer, ModalWrapper, ModalDetails } from './ModalElements'
import Modal from 'react-bootstrap/Modal';

const InfoModal = ({type, d, topline, l, lan}) => {

  const renderSwitch = (type, d) => {
    switch(type) {
      case 'assignment':
        return <InfoModalAssignments d={d} l={l} lan={lan}/>
      case 'employment':
        return <InfoModalEmployments d={d} l={l} lan={lan}/>
      case 'education':
        return <InfoModalEducations d={d} l={l} lan={lan}/>
      case 'certificate':
        return <InfoModalCertificates d={d} l={l} lan={lan}/>
      default:
        <div></div>
        break;
    }
  }

  return (
    <>
      <ModalContainer>
        <ModalWrapper>
        <Modal.Header closeButton>
          <Modal.Title>{topline}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalDetails>
          {renderSwitch(type, d)}
          </ModalDetails>
        </Modal.Body>
        </ModalWrapper>
      </ModalContainer>
    </>
  )
}

export default InfoModal
