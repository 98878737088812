import styled from 'styled-components'

export const EducationDegree = styled.p`
  font-style: italic;
  font-size: 1.2rem;

  @media screen and (max-width: 1048px){
    text-align: center;
  }
`

export const EducationProgramme = styled.h4`
  padding: 0px 10px;
  font-size: 1.0rem;

  @media screen and (max-width: 1048px){
    text-align: center;
  }
` 
