import styled from "styled-components";
import { FaTimes } from 'react-icons/fa'

export const ModalContainer = styled.div`
  border-color: black;
  background-color: #e1e1e1;
`

export const ModalWrapper = styled.div`
  z-index: 10;
  padding: 24px 24px;

  @media screen and (max-width: 1048px){
    padding: 2px;
  }
`

export const ModalTopLine1 = styled.h1`
  text-transform: uppercase;
  font-size: 0.7rem;
`

export const ModalTopLine2 = styled.h2`
  text-transform: uppercase;
  font-size: 0.6rem;
`

export const ModalGenericText = styled.div`
  font-size: 0.6rem;
  white-space: pre-wrap;

  p {
    font-size: 0.6rem;
  }
`

export const ModalGenericList = styled.div`
  font-size: 0.6rem;

   ul > li {
    margin: 0 8px;
    font-size: 0.6rem;
    list-style-type: none;
   }
`

export const ModalDetails = styled.div`
  margin-block-end: 0;
  margin-block-start: 0;
  line-height: 1.6;
`

export const Bold = styled.span`
  font-weight: bold;
  font-size: 0.6rem;
`

export const ModalClient = styled.p`
  font-size: 0.6rem;
  margin: 0;
  padding: 0;
`
export const ModalRegNo = styled.p`
  font-size: 0.6rem;
  margin: 0;
`

export const ModalTable = styled.table`
  width: 100%;
  padding: 5px;
  th:first-child,
  td:first-child {
    width: 12em;
    min-width: 4em;
    max-width: 15em;
    word-break: break-all;
  }
  th, td, li {
    font-size: 0.6rem;
  }
  td {
    padding: 2px;
  }
  tr {
    border-bottom: 1px solid black;
  }
  ul {
    padding: 0;
    margin: 0;
    font-size: 0;
  }
   ul > li {
    display: inline;
    margin: 0 0px;
    font-size: 0.6rem;
    list-style-type: none;
   }

  ul > li::after {
    content: ', ';
  }

  ul > li:last-child::after {
    content: '';
  }

  @media screen and (max-width: 1048px){
    thead tr th:first-child {
      width: 7em;
      min-width: 7em;
      max-width: 7em;
      word-break: break-all;
    }
    tbody tr td:first-child {
      width: 7em;
      min-width: 7em;
      max-width: 7em;
      word-break: break-all;
    }
  }
`

export const ModalSubTable = styled.table`
  width: 100%;
  td:first-child {
    width: 80%;
    min-width: 2em;
    max-width: 30em;
  }
  th, td, li {
    font-size: 0.6rem;
  }
  td {
    padding: 2px;
  }
  tr {
    border-bottom: 1px solid black;
    cursor: pointer;
  }
  tr:last-child {
    border-bottom: 0;
  }
`

export const ModalRoles = styled.p`
  font-size: 0.6rem;
  margin-block-end: 0;
  line-height: 1.6;
  ul {
    padding: 0;
    margin: 0;
  }
   ul > li {
    display: inline;
    margin: 0 0px;
    font-size: 0.6rem;
    list-style-type: none;
   }

   ul > li:first-child::before {
    content: 'Roles: ';
    font-weight: bold;
   }

  ul > li::after {
    content: ', ';
  }

  ul > li:last-child::after {
    content: '';
   }
`

export const ModalDescriptionLong = styled.p`
  font-size: 0.6rem;
  margin: 0;
`

export const ModalStartEnd = styled.p`
  font-size: 0.6rem;
  margin: 0;
`


export const ModalLocation = styled.div`
  font-size: 0.6rem;
  margin: 0;
`

export const ModalAssignmentTasks = styled.div`
  font-size: 0.6rem;
  line-height: 1;
  ul {
    margin: 0;
    padding: 0;
  }
   ul > li {
    display: inline;
    margin: 0 0px;
    font-size: 0.6rem;
    list-style-type: none;
   }

   ul > li:first-child::before {
    content: 'Tasks: ';
    font-weight: bold;
   }

  ul > li::after {
    content: ', ';
  }

  ul > li:last-child::after {
    content: '';
   }
`

export const ModalSoftSkills = styled.div`
  font-size: 0.6rem;
  line-height: 1;
  ul {
    padding: 0;
    margin: 0;
  }
   ul > li {
    display: inline;
    margin: 0 0px;
    font-size: 0.6rem;
    list-style-type: none;
   }

   ul > li:first-child::before {
    content: 'Soft skills: ';
    font-weight: bold;
   }

  ul > li::after {
    content: ', ';
  }

  ul > li:last-child::after {
    content: '';
   }
`

export const ModalAssignmentTools = styled.div`
  font-size: 0.6rem;
  line-height: 1;
  ul {
    margin: 0;
    padding: 0;
  }
   ul > li {
    display: inline;
    margin: 0 0px;
    font-size: 0.6rem;
    list-style-type: none;
   }

   ul > li:first-child::before {
    content: 'Tools: ';
    font-weight: bold;
   }

  ul > li::after {
    content: ', ';
  }

  ul > li:last-child::after {
    content: '';
   }
`

export const ModalAssignmentProgramming = styled.div`
  font-size: 0.6rem;
  line-height: 1;
  ul {
    padding: 0;
    margin: 0;
  }
   ul > li {
    display: inline;
    margin: 0 0px;
    font-size: 0.6rem;
    list-style-type: none;
   }

   ul > li:first-child::before {
    content: 'Programming: ';
    font-weight: bold;
   }

  ul > li::after {
    content: ', ';
  }

  ul > li:last-child::after {
    content: '';
   }
`

export const CloseIcon = styled(FaTimes)`
  color: #1e1e1e;
`

export const Icon = styled.div`
  position: absolute;
  top: 0rem;
  right: 0.5rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`

export const ModalEducationCourse = styled.div`
  font-size: 0.6rem;
`
