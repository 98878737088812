import React, { useState } from 'react'
import { ModalEditForm, ModalEditWrapper } from '../EditModal/EditModalElements';
import {ModalMain, ModalMainLanSpec, ModalMainCommon, ModalSubTitle} from './AddModalElements'
import axios from 'axios';
import Modal from "react-bootstrap/Modal";
import reqConfig from '../../app/vars';
import makeToast from '../../pages/Toaster'

// TODO: Add the option to include PDF (proof, diplomas etc)

const AddModalCertificate = ({lastid, certificates, setCertificates, closeModal}) => {

  const token = localStorage.getItem('accessToken')

  const [cert, setCert] = useState({
    "name": {
      "sv": "",
      "en": ""
    },
    "description": {
      "sv": "",
      "en": ""
    },
    "location": "",
    "coords": [],
    "date": "",
    "issuer": ""
  });

  const inputHandler = (e) => {
    let certCopy = {...cert};
    let tmp = e.target.name;
    let tmp2 = tmp.split('.');
    if (tmp2[tmp2.length-1] === 'en' || tmp2[tmp2.length-1] === 'sv') {
      certCopy[tmp2[0]][tmp2[1]] = e.target.value;
    } else if (tmp === 'lat') {
      certCopy['coords'][0] = Number(e.target.value);
    } else if (tmp === 'long') {
      certCopy['coords'][1] = Number(e.target.value);
    } else {
      certCopy[tmp2[0]] = e.target.value;
    }
    setCert(certCopy)
  }

  const modifyData = () => {
    let certCopy = {...cert};
    let certMainCopy = [...certificates]
    //certCopy[id].title.en = stringToArray(certCopy[id].title.en);
    //certCopy[id].title.sv= stringToArray(certCopy[id].title.sv);
    //certCopy[id].assignmentid = stringToArray(certCopy[id].assignmentid)
    //certCopy[id].assignmentid.map((assignment) => {
    //  Number(assignment)
    //})
    setCert(certCopy);
    certMainCopy.push(certCopy)
    setCertificates(certMainCopy)
  }

  const handleOnSubmit = (e) => {
    e.preventDefault();
    modifyData();
    updateCertificate();
    closeModal()
  }

  const updateCertificate = () => {
    const uA = async () => {
      await axios.post(
        '/api/v1/dashboard/edit/certificate', cert, reqConfig(token) 
      )
        .then(response => {
          makeToast('success', 'Added certificate')
        })
        .catch(err => {
          makeToast('error', err.data.msg);
          let certMainCopy = [...certificates]
          setCertificates(certMainCopy)
        })
    }

    uA();
  }

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Create Certificate</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <ModalEditWrapper>
        <ModalEditForm onSubmit={(e) => handleOnSubmit(e)}>
          <ModalMain>
          <ModalSubTitle>Language Specific</ModalSubTitle>
            <ModalMainLanSpec>
              <table>
                <thead>
                  <tr>
                    <th>Property</th><th>Content</th><th>Egenskap</th><th>Innehåll</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <label htmlFor={'name'}>Certificate Name:</label> 
                    </td>
                    <td>
                      <input type='text' id={'name'} name='name.en' onChange={(e) => inputHandler(e)}/>
                    </td>
                    <td>
                      <label htmlFor={'namesv'}>Certifikatnamn:</label> 
                    </td>
                    <td>
                      <input title='' type='text' id={'namesv'} name='name.sv' onChange={(e) => inputHandler(e)}/>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label htmlFor={'description'}>Description:</label> 
                    </td>
                    <td>
                      <textarea rows='4' id={'description'} name='description.en' onChange={(e) => inputHandler(e)}/>
                    </td>
                    <td>
                      <label htmlFor={'descriptionsv'}>Beskrivning:</label> 
                    </td>
                    <td>
                      <textarea rows='4' id={'descriptionsv'} name='description.sv' onChange={(e) => inputHandler(e)}/>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ModalMainLanSpec>
            <ModalSubTitle>Non language specific</ModalSubTitle>
            <ModalMainCommon>
              <label htmlFor={'issuer'}>Issuer:</label> 
              <input title='Issuing entity' type='text' id={'issuer'} name='issuer' onChange={(e) => inputHandler(e)}/>
              <label htmlFor='location'>Google Location:</label>
              <input type='text' id='location' name='location' onChange={(e) => inputHandler(e)}/>
              <label htmlFor='lat'>Latitude:</label>
              <input title='Decimals are seperated by dot (.).' type='text' id='lat' name='lat' onChange={(e) => inputHandler(e)}/>
              <label htmlFor='long'>Longitude:</label>
              <input title='Decimals are seperated by dot (.).' type='text' id='long' name='long' onChange={(e) => inputHandler(e)}/>
              <label htmlFor='long'>Issue date:</label>
              <input title='ISO standard (YYYY-MM-DD).' type='date' id='date' name='date' onChange={(e) => inputHandler(e)}/>
            </ModalMainCommon>
          </ModalMain>
          <input type='submit'/>
        </ModalEditForm>
      </ModalEditWrapper>
    </Modal.Body>
    </>
  )
}

export default AddModalCertificate

