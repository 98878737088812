import React, { useState } from 'react'
import { ModalEditForm, ModalEditWrapper } from './EditModalElements';
import {ModalMain, ModalMainLanSpec, ModalMainCommon, ModalSubTitle} from '../AddModal/AddModalElements'
import axios from 'axios';
import Modal from "react-bootstrap/Modal";
import { FaTrash } from 'react-icons/fa';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import reqConfig from '../../app/vars';
import makeToast from '../../pages/Toaster';

const EditModalCertificate = ({d, id, certificates, setCertificates, closeModal}) => {

  const token = localStorage.getItem('accessToken')
  
  const [cert, setCert] = useState(certificates)

  const inputHandler = (e) => {
    let certCopy = {...cert};
    let tmp = e.target.name;
    let tmp2 = tmp.split('.');
    if (tmp2[tmp2.length-1] === 'en' || tmp2[tmp2.length-1] === 'sv') {
      certCopy[id][tmp2[0]][tmp2[1]] = e.target.value;
    } else if (tmp === 'lat') {
      certCopy[id]['coords'][0] = Number(e.target.value);
    } else if (tmp === 'long') {
      certCopy[id]['coords'][1] = Number(e.target.value);
    } else {
      certCopy[id][tmp2[0]] = e.target.value;
    }
    setCert(certCopy)
  }

  const modifyData = () => {
    let certCopy = {...cert};
    setCert(certCopy);
  }


  const handleOnSubmit = (e) => {
    e.preventDefault();
    modifyData();
    closeModal();
    updateCertificate();
  }

  const deleteConfirmation = (type, cid = undefined, scid = undefined) => {
    closeModal();
    confirmAlert({
      title: 'Confirm before submit',
      message: `Are you sure you want to delete this ${type}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            switch (type) {
              case 'certificate':
                deleteCertificate()
                break;
              default:
                console.log('nothing happened')
            }}
        },
        {
          label: 'No',
          onClick: () => console.log('aborted')
        }
      ]
    })
  }

  const deleteCertificate = () => {
    let dataCopy = [...certificates]
    let k = 0
    for (let i = 0; i <= dataCopy.length; i++) {
      if (dataCopy[i].certificateid === d.certificateid) {
        break;
      }
      k++;
    }
    if (k > dataCopy.length) {
      return console.error('something is wrong');
    }
    dataCopy.splice(k, 1);
    deleteDocument()
    setCertificates(dataCopy)
  }

  const deleteDocument = () => {
    const dD = async () => {
      await axios.delete(
        '/api/v1/dashboard/edit/certificate/' + d._id, 
        reqConfig(token)
      )
      .then(response => {
        makeToast('success', 'Deletion successful')
      })
      .catch(err => console.log(err));
    }

    dD();
  }

  const updateCertificate = () => {
    const uA = async () => {
      await axios.patch(
        '/api/v1/dashboard/edit/certificate', 
        d,
        reqConfig(token)
      )
        .then(response => {
          makeToast('success', 'Update successful');
        })
        .catch(err => makeToast('error', err.data.msg));
    }

    uA();
  }

  const a = certificates[id];

  return (
    <>
    <Modal.Header closeButton>
      <Modal.Title>Edit Certificate ID {a.certificateid}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <ModalEditWrapper>
        <ModalEditForm onSubmit={(e) => handleOnSubmit(e)}>
          <ModalMain>
          <ModalSubTitle>Language Specific</ModalSubTitle>
            <ModalMainLanSpec>
              <table>
                <thead>
                  <tr>
                    <th>Property</th><th>Content</th><th>Egenskap</th><th>Innehåll</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <label htmlFor={'name'}>Name:</label> 
                    </td>
                    <td>
                      <input type='text' id='name' name='name.en' value={d.name.en} onChange={(e) => inputHandler(e)}/>
                    </td>
                    <td>
                      <label htmlFor='namesv'>Kursnamn:</label> 
                    </td>
                    <td>
                      <input type='text' id='namesv' name='name.sv' value={d.name.sv} onChange={(e) => inputHandler(e)}/>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label htmlFor={'description'}>Description:</label> 
                    </td>
                    <td>
                      <textarea rows='4' id='description' name='description.en' value={d.description.en} onChange={(e) => inputHandler(e)}/>
                    </td>
                    <td>
                      <label htmlFor='descriptionsv'>Beskrivning:</label> 
                    </td>
                    <td>
                      <textarea rows='4' id='descriptionsv' name='description.sv' value={d.description.sv} onChange={(e) => inputHandler(e)}/>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ModalMainLanSpec>
            <ModalSubTitle>Non language specific</ModalSubTitle>
            <ModalMainCommon>
              <label htmlFor='issuer'>Issuer</label>
              <input type='text' id='issuer' name='issuer' value={d.issuer} onChange={(e) => inputHandler(e)}/>
              <label htmlFor='location'>Google Location</label>
              <input type='text' id='location' name='location' value={d.location} onChange={(e) => inputHandler(e)}/>
              <label htmlFor='lat'>Latitude</label>
              <input title='Decimals are seperated by dot (.).' type='text' id='lat' name='lat' value={d.coords[0]} onChange={(e) => inputHandler(e)}/>
              <label htmlFor='long'>Longitude</label>
              <input title='Decimals are seperated by dot (.).' type='text' id='long' name='long' value={d.coords[1]} onChange={(e) => inputHandler(e)}/>
              <label htmlFor='date'>Date</label>
              <input title='ISO standard (YYYY-MM-DD).' type='date' id='date' name='date' value={d.date} onChange={(e) => inputHandler(e)}/>
            </ModalMainCommon>
          </ModalMain>
          <input type='submit'/>
        </ModalEditForm>
    <FaTrash title='Delete Certificate' onClick={() => deleteConfirmation('certificate')} />
      </ModalEditWrapper>
      </Modal.Body>
    </>
  )
}

export default EditModalCertificate

