import React, { useState } from "react";
import {
  ModalEditForm,
  ModalEditWrapper,
} from "../EditModal/EditModalElements";
import {
  ModalMain,
  ModalMainLanSpec,
  ModalMainCommon,
  ModalSubTitle,
} from "./AddModalElements";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import reqConfig from "../../app/vars";
import makeToast from "../../pages/Toaster";

const AddModalAssignments = ({
  lastid,
  assignments,
  setAssignments,
  closeModal,
}) => {
  const token = localStorage.getItem("accessToken");

  const [ass, setAss] = useState({
    name: {
      en: "",
      sv: "",
    },
    client: "",
    regno: "",
    location: "",
    coords: [],
    roles: {
      en: "",
      sv: "",
    },
    startdate: "",
    enddate: "",
    descriptionshort: {
      en: "",
      sv: "",
    },
    descriptionlong: {
      en: "",
      sv: "",
    },
    tasks: {
      en: [],
      sv: [],
    },
    softskills: {
      en: [],
      sv: [],
    },
    tools: [],
    programming: [],
  });

  const inputHandler = (e) => {
    let assCopy = { ...ass };
    let tmp = e.target.name;
    let tmp2 = tmp.split(".");
    if (tmp2[tmp2.length - 1] === "en" || tmp2[tmp2.length - 1] === "sv") {
      assCopy[tmp2[0]][tmp2[1]] = e.target.value;
    } else if (tmp === "lat") {
      assCopy["coords"][0] = Number(e.target.value);
    } else if (tmp === "long") {
      assCopy["coords"][1] = Number(e.target.value);
    } else {
      assCopy[tmp2[0]] = e.target.value;
    }
    setAss(assCopy);
  };

  const modifyData = () => {
    let assCopy = { ...ass };
    let assMainCopy = [...assignments];
    setAss(assCopy);
    assMainCopy.push(assCopy);
    setAssignments(assMainCopy);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    modifyData();
    updateAssignment();
    closeModal();
  };

  const updateAssignment = () => {
    const uA = async () => {
      await axios
        .post("/api/v1/dashboard/edit/assignment", ass, reqConfig(token))
        .then((response) => {
          console.log("Added assignment successfully");
          console.log(response);
          makeToast("success", "Assignment added");
        })
        .catch((err) => {
          console.error(err);
          makeToast("error", err.message);
          let assMainCopy = [...assignments];
          setAssignments(assMainCopy);
        });
    };

    uA();
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Create assignment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ModalEditWrapper>
          <ModalEditForm onSubmit={(e) => handleOnSubmit(e)}>
            <ModalMain>
              <ModalSubTitle>Language Specific</ModalSubTitle>
              <ModalMainLanSpec>
                <table>
                  <thead>
                    <tr>
                      <th>Property</th>
                      <th>Content</th>
                      <th>Egenskap</th>
                      <th>Innehåll</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <label htmlFor={"assname"}>Assignment name</label>
                      </td>
                      <td>
                        <input
                          type="text"
                          id={"assname"}
                          name="name.en"
                          onChange={(e) => inputHandler(e)}
                        />
                      </td>
                      <td>
                        <label htmlFor={"assnamesv"}>Uppdragsnamn</label>
                      </td>
                      <td>
                        <input
                          type="text"
                          id={"assnamesv"}
                          name="name.sv"
                          onChange={(e) => inputHandler(e)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="roles">Roles</label>
                      </td>
                      <td>
                        <textarea
                          title="Seperate multiple roles by comma (,)."
                          id="roles"
                          name="roles.en"
                          onChange={(e) => inputHandler(e, true)}
                        />
                      </td>
                      <td>
                        <label htmlFor="rolessv">Roller</label>
                      </td>
                      <td>
                        <textarea
                          title="Seperate multiple roles by comma (,)."
                          id="rolessv"
                          name="roles.sv"
                          onChange={(e) => inputHandler(e, true)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="descriptionShort">
                          Short description
                        </label>
                      </td>
                      <td>
                        <textarea
                          rows="4"
                          id="descriptionShort"
                          name="descriptionshort.en"
                          onChange={(e) => inputHandler(e)}
                        />
                      </td>
                      <td>
                        <label htmlFor="descriptionshortsv">
                          Kort beskrivning
                        </label>
                      </td>
                      <td>
                        <textarea
                          rows="4"
                          id="descriptionshortsv"
                          name="descriptionshort.sv"
                          onChange={(e) => inputHandler(e)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="descriptionLong">
                          Long description
                        </label>
                      </td>
                      <td>
                        <textarea
                          rows="5"
                          id="descriptionLong"
                          name="descriptionlong.en"
                          onChange={(e) => inputHandler(e)}
                        />
                      </td>
                      <td>
                        <label htmlFor="descriptionlongsv">
                          Lång beskrivning
                        </label>
                      </td>
                      <td>
                        <textarea
                          rows="5"
                          id="descriptionlongsv"
                          name="descriptionlong.sv"
                          onChange={(e) => inputHandler(e)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="tasks">Tasks</label>
                      </td>
                      <td>
                        <textarea
                          title="Seperate multiple tasks by comma (,)."
                          id="tasks"
                          name="tasks.en"
                          onChange={(e) => inputHandler(e, true)}
                        />
                      </td>
                      <td>
                        <label htmlFor="taskssv">Uppgifter</label>
                      </td>
                      <td>
                        <textarea
                          title="Seperate multiple tasks by comma (,)."
                          id="taskssv"
                          name="tasks.sv"
                          onChange={(e) => inputHandler(e, true)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label htmlFor="softskills">Softskills</label>
                      </td>
                      <td>
                        <textarea
                          title="Seperate multiple skills by comma (,)."
                          id="softskills"
                          name="softskills.en"
                          onChange={(e) => inputHandler(e, true)}
                        />
                      </td>
                      <td>
                        <label htmlFor="softskillssv">
                          Personliga färdigheter
                        </label>
                      </td>
                      <td>
                        <textarea
                          title="Seperate multiple skills by comma (,)."
                          id="softskillssv"
                          name="softskills.sv"
                          onChange={(e) => inputHandler(e, true)}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ModalMainLanSpec>
              <ModalSubTitle>Non language specific</ModalSubTitle>
              <ModalMainCommon>
                <label htmlFor="client">Client</label>
                <input
                  type="text"
                  id="client"
                  name="client"
                  onChange={(e) => inputHandler(e)}
                />
                <label htmlFor="regno">Client reg. number</label>
                <input
                  type="text"
                  id="regno"
                  name="regno"
                  onChange={(e) => inputHandler(e)}
                />
                <label htmlFor="location">Google Location</label>
                <input
                  type="text"
                  id="location"
                  name="location"
                  onChange={(e) => inputHandler(e)}
                />
                <label htmlFor="lat">Latitude</label>
                <input
                  title="Decimals are seperated by dot (.)."
                  type="text"
                  id="lat"
                  name="lat"
                  onChange={(e) => inputHandler(e)}
                />
                <label htmlFor="long">Longitude</label>
                <input
                  title="Decimals are seperated by dot (.)."
                  type="text"
                  id="long"
                  name="long"
                  onChange={(e) => inputHandler(e)}
                />
                <label htmlFor="long">Start date</label>
                <input
                  title="ISO standard (YYYY-MM-DD)."
                  type="date"
                  id="startdate"
                  name="startdate"
                  onChange={(e) => inputHandler(e)}
                />
                <label htmlFor="long">End date</label>
                <input
                  title="ISO standard (YYYY-MM-DD)."
                  type="date"
                  id="enddate"
                  name="enddate"
                  onChange={(e) => inputHandler(e)}
                />
                <label htmlFor="tools">Tools</label>
                <textarea
                  title="Seperate multiple tools by comma (,)."
                  id="tools"
                  name="tools"
                  onChange={(e) => inputHandler(e, true)}
                />
                <label htmlFor="programming">Programming</label>
                <textarea
                  title="Seperate multiple programming languages by comma (,)."
                  id="programming"
                  name="programming"
                  onChange={(e) => inputHandler(e, true)}
                />
              </ModalMainCommon>
            </ModalMain>
            <input type="submit" />
          </ModalEditForm>
        </ModalEditWrapper>
      </Modal.Body>
    </>
  );
};

export default AddModalAssignments;
