import styled from 'styled-components'

export const LogoutContainer = styled.div`
  background-color: #1e1e1e;
  display: flex;
  flex-flow: column nowrap;
  height: 100vh;
  padding: 50px 50px;
`

export const LogoutTopLine = styled.h1`
  color: #01bf71;
  color: #9d9d9d;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
`

export const LogoutSection = styled.div`
  color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
`

export const LogoutForm = styled.form`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  row-gap: 0;
`

export const LogoutFormInput = styled.div`
  width: 100%;
  padding: 10px 0;
  display: grid;
  grid-template-columns: 1fr;
`

export const LogoutButton = styled.button`
  background-color: #5d5d5d;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
  width: 100%;
  text-transform: uppercase;
  color: #9d9d9d;
  font-weight: bolder;
  transition: all 0.2s;
`

