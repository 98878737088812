import React, { useState } from "react";
import axios from "axios";
import makeToast from "../../pages/Toaster";
import { useNavigate } from "react-router-dom";
import {
  LoginContainer,
  LoginTopLine,
  LoginSection,
  LoginForm,
  LoginFormInput,
  LoginButton,
} from "./LoginElements";

const Login = ({ logIn, setToken, l }) => {
  const navigate = useNavigate();

  const [inputs, setInputs] = useState({});
  const handleInputChange = (e) => {
    setInputs((prevState) => ({ ...prevState, [e.target.id]: e.target.value }));
  };

  //const handleSubmit = (e) => {
  //  e.preventDefault()
  //  axios.post('api/v1/auth/login', inputs)
  //    .then(response => console.log(response))
  //    .catch(err => console.log(err))
  //}
  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("api/v1/auth/login", inputs)
      .then((response) => {
        makeToast("success", response.data.msg);
        localStorage.setItem("accessToken", response.data.token);
        setToken(response.data.token);
        navigate(-1, { replace: true });
      })
      .catch((err) => {
        console.log(err);
        localStorage.removeItem("accessToken");
        makeToast("error", err.data.msg);
        navigate("/login");
      });
  };

  return (
    <>
      <LoginContainer>
        <LoginTopLine>{l ? "LOGIN" : "LOGGA IN"}</LoginTopLine>
        <LoginSection>
          <LoginForm onSubmit={handleSubmit}>
            <LoginFormInput>
              <label htmlFor="email">{l ? "E-mail" : "E-post"}</label>
              <input
                type="email"
                name="email"
                value={inputs.email || ""}
                id="email"
                onChange={handleInputChange}
                autoComplete="on"
                placeholder="name@example.com"
              />
            </LoginFormInput>
            <LoginFormInput>
              <label htmlFor="password">{l ? "Password" : "Lösenord"}</label>
              <input
                type="password"
                name="current-password"
                value={inputs.password || ""}
                id="password"
                onChange={handleInputChange}
                autoComplete="on"
              />
            </LoginFormInput>
            <LoginButton type="submit">{l ? "LOGIN" : "LOGGA IN"}</LoginButton>
          </LoginForm>
        </LoginSection>
      </LoginContainer>
    </>
  );
};

export default Login;
