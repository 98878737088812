import styled from 'styled-components'

export const DashboardContainer = styled.div`
  background-color: #fffff2;
  min-height: 100vh;
  width: 100%;
  color: #000;
  display: grid;
  grid-template-columns: 1fr 7fr 1fr;
  color: #000;

  @media screen and (max-width: 1048px){
    font-size: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media print {
    background-color: #fff;
    color: #000;
  }
`

export const DashboardWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin: auto 0;

  @media screen and (max-width: 1048px){
    margin: auto 10px;
  }
`

export const DashboardSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media screen and (max-width: 1048px){
  }

  @media print{
    break-after: page;
  }
`

export const DashboardSectionTitle = styled.h3`
  width: 80%;
  font-weight: bold;
  letter-spacing: 1.4px;
  border-bottom: 4px solid #9d9d9d;
  margin: 4rem auto;
`

export const DashboardItem = styled.div`
  color: #000;
  width: 90%;
  margin: auto 100px;

  @media screen and (max-width: 1048px){
    margin: auto 0px;
    width: 100%;
  }

  @media print{
    color: #000;
    width: 90vw;
    margin: 0px;
    display: inline;
  }
`

export const DashboardNavContainer = styled.div`
  padding: 10px 50px;
  
  @media screen and (max-width: 1048px){
    display: none;
  }

  @media print {
    display: none;
  }
`

export const DashboardNavWrapper= styled.div`
  position: fixed;
  top: 50%;

  ul > li {
    margin: 0 0px;
    font-size: 0.7em;
    cursor: pointer;
  }
  ul {
    transform: translateY(-50%);
    list-style-type: none;
  }
  li:hover{
    color: white;
  }
`

export const DashboardAddItem=styled.span`
  display: inline;
`

export const DashboardTitleText = styled.span`
  display: block;
  text-align: center;
  text-transform: uppercase;
`

export const NavMobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 1048px){
    display: block;
    position: fixed;
    top: -12px;
    right: 10px;
    font-size: 1.6rem;
    cursor: pointer;
    color: #fff;
    z-index: 1000;
  }
`
