import React from 'react'
import EditModalAssignments from './EditModalAssignment.js'
import EditModalEmployment from './EditModalEmployment.js'
import EditModalEducation from './EditModalEducation.js'
import EditModalCertificate from './EditModalCertificate.js'
import { ModalContainer, ModalWrapper, ModalDetails } from '../InfoModal/ModalElements'

const EditModal = ({type, d, id, data, setEducations, setAssignments, setEmployments, setCertificates, closeModal, openModal }) => {

  const renderSwitch = (type, d) => {
    switch(type) {
      case 'assignment':
        return <EditModalAssignments d={d} id={id} assignments={data} setAssignments={setAssignments} closeModal={closeModal} openModal={openModal}/>
      case 'employment':
        return <EditModalEmployment d={d} id={id} employments={data} setEmployments={setEmployments} closeModal={closeModal} />
      case 'education':
        return <EditModalEducation d={d} id={id} educations={data} setEducations={setEducations} closeModal={closeModal} openModal={openModal}/>
      case 'certificate':
        return <EditModalCertificate d={d} id={id} certificates={data} setCertificates={setCertificates} closeModal={closeModal} />
      default:
        <div></div>
        break;
    }
  }

  return (
    <>
      <ModalContainer>
        <ModalWrapper>
          <ModalDetails>
          {renderSwitch(type, d)}
          </ModalDetails>
        </ModalWrapper>
      </ModalContainer>
    </>
  )
}

export default EditModal
