import React, {useState} from 'react'
import { convertDMS, monthDiff } from '../../app/helperFunctions';
import { ModalGenericText, ModalTable, ModalSubTable } from './ModalElements'
import Modal from 'react-bootstrap/Modal';

const InfoModalEducations = ({d, l, lan}) => {

  const start = new Date(Date.parse(d.startdate));
  const end = new Date(Date.parse(d.enddate));
  const months = monthDiff(start, end, lan);
  const latlong = convertDMS(d.coords[0], d.coords[1]);
  const courses = d.course;

  const [alert, setAlert] = useState(false);
  const [content, setContent] = useState();
  const [title, setTitle] = useState();

  const alertDescription = (content, title) => {
    setContent(content);
    setTitle(title);
    setAlert(true);
  }

  return (
    <>
      <ModalTable>
        <tbody>
          <tr>
            <td>{l ? 'Education ID:':'Utbildnings-ID'}</td><td>{d.educationid}</td>
          </tr>
          <tr>
            <td>{l ? 'School:':'Skola'}</td><td>{d.school[lan]}</td>
          </tr>
          <tr>
            <td>{l ? 'Description:':'Beskrivning:'}</td><td>{d.description[lan]}</td>
          </tr>
          <tr>
            <td>{l ? 'Course name:':'Kurstitel'}</td><td><ModalSubTable><tbody>{courses.map((course, i) => (
              <tr key={i} onClick={() => alertDescription(course.description[lan], course.name[lan])} >
                <td>{course.name[lan]}</td><td>{l ? 'credits':'poäng'}: {course.credits}</td>
              </tr>
            ))}</tbody></ModalSubTable></td>
          </tr>
          <tr>
            <td>{l ? 'Start - End:':'Start - Slut'}</td><td>{d.startdate} - {d.enddate} {l ? '(duration: ':'(varaktighet: '}{months}.)</td>
          </tr>
          <tr>
            <td>{l ? 'Location':'Plats'}:</td><td>{latlong}, Google short: {d.location}</td>
          </tr>
        </tbody>
      </ModalTable>
      <Modal
        show={alert}
        onHide={() => setAlert(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalGenericText>{content}</ModalGenericText>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default InfoModalEducations
