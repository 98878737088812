import React, { useState } from 'react'
import { ModalEditForm, ModalEditWrapper } from './EditModalElements';
import {ModalMain, ModalMainLanSpec, ModalMainCommon, ModalSubTitle} from '../AddModal/AddModalElements'
import axios from 'axios';
import { stringToArray } from '../../app/helperFunctions.js'
import Modal from "react-bootstrap/Modal";
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { FaTrash } from 'react-icons/fa';
import reqConfig from '../../app/vars';
import makeToast from '../../pages/Toaster.js'

const EditModalAssignment = ({d, id, assignments, setAssignments, closeModal }) => {

  const token = localStorage.getItem('accessToken');

  const [ass, setAss] = useState(assignments)

  const inputHandler = (e) => {
    let assCopy = {...ass};
    let tmp = e.target.name;
    let tmp2 = tmp.split('.');
    if (tmp2[tmp2.length-1] === 'en' || tmp2[tmp2.length-1] === 'sv') {
      assCopy[id][tmp2[0]][tmp2[1]] = e.target.value;
    } else if (tmp === 'lat') {
      assCopy[id]['coords'][0] = Number(e.target.value);
    } else if (tmp === 'long') {
      assCopy[id]['coords'][1] = Number(e.target.value);
    } else {
      assCopy[id][tmp2[0]] = e.target.value;
    }
    setAss(assCopy)
  }

  const modifyData = () => {
    let assCopy = {...ass};
    assCopy[id].roles.en = stringToArray(assCopy[id].roles.en);
    assCopy[id].roles.sv= stringToArray(assCopy[id].roles.sv);
    assCopy[id].tasks.en= stringToArray(assCopy[id].tasks.en);
    assCopy[id].tasks.sv= stringToArray(assCopy[id].tasks.sv);
    assCopy[id].softskills.en= stringToArray(assCopy[id].softskills.en);
    assCopy[id].softskills.sv= stringToArray(assCopy[id].softskills.sv);
    assCopy[id].tools = stringToArray(assCopy[id].tools);
    assCopy[id].programming = stringToArray(assCopy[id].programming);
    setAss(assCopy);
  }


  const handleOnSubmit = (e) => {
    e.preventDefault();
    modifyData();
    closeModal();
    updateAssignment();
  }

  const deleteConfirmation = (type, cid = undefined, scid = undefined) => {
    closeModal();
    confirmAlert({
      title: 'Confirm before submit',
      message: `Are you sure you want to delete this ${type}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            switch (type) {
              case 'assignment':
                deleteAssignment()
                deleteDocument();
                break;
              default:
                console.log('nothing happened')
                break;
            }}
        },
        {
          label: 'No',
          onClick: () => console.log('aborted')
        }
      ]
    })
  }

  const deleteAssignment = () => {
    let dataCopy = [...assignments]
    let k = 0;
    for (let i = 0; i <= dataCopy.length; i++) {
      if(dataCopy[i].assignmentid === d.assignmentid) {
        break;
      }
      k++;
    }
    if (k > dataCopy.length) {
      return console.log('something is wrong');
    }
    dataCopy.splice(k, 1);
    setAssignments(dataCopy);
  }

  const deleteDocument = () => {
    const dD = async () => {
      await axios.delete(
        '/api/v1/dashboard/edit/assignment/' + d._id, 
          reqConfig(token)
      )
      .then(response => {
        makeToast('success', 'Deletion successful')
      })
      .catch(err => console.log(err));
    }

    dD();
  }

  const updateAssignment = () => {
    const uA = async () => {
      await axios.patch(
        '/api/v1/dashboard/edit/assignment', 
        d,
        reqConfig(token)
      )
        .then(response => {
          makeToast('success', 'Update successful');
        })
        .catch(err => makeToast('error', err.data.msg));
    }

    uA();
  }

  const a = assignments[id];

  return (
    <>
    <Modal.Header closeButton>
      <Modal.Title>Edit assignment ID {d.assignmentid}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <ModalEditWrapper>
        <ModalEditForm onSubmit={(e) => handleOnSubmit(e)}>
          <ModalMain>
          <ModalSubTitle>Language Specific</ModalSubTitle>
            <ModalMainLanSpec>
              <table>
                <thead>
                  <tr>
                    <th>Property</th><th>Content</th><th>Egenskap</th><th>Innehåll</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <label htmlFor={'assname' + id}>Assignment name </label>
                    </td>
                    <td>
                      <input type='text' id={'assname' + id} name='name.en' value={a.name.en || ""} onChange={(e) => inputHandler(e)}/>
                    </td>
                    <td>
                      <label htmlFor={'assnamesv' + id}>Uppdragsnamn</label>
                    </td>
                    <td>
                      <input type='text' id={'assnamesv' + id} name='name.sv' value={a.name.sv || ""} onChange={(e) => inputHandler(e)}/>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label htmlFor='roles'>Roles</label>
                    </td>
                    <td>
                      <textarea title='Seperate multiple roles by comma (,).' id='roles' name='roles.en' value={d.roles.en || ""} onChange={(e) => inputHandler(e, true)}/>
                    </td>
                    <td>
                      <label htmlFor='rolessv'>Roll(er)</label>
                    </td>
                    <td>
                      <textarea title='Seperate multiple roles by comma (,).' id='rolessv' name='roles.sv' value={d.roles.sv || ""} onChange={(e) => inputHandler(e, true)}/>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label htmlFor='descriptionShort'>Short description</label>
                    </td>
                    <td>
                      <textarea rows='4' id='descriptionShort' name='descriptionshort.en' value={d.descriptionshort.en || ""} onChange={(e) => inputHandler(e)}/>
                    </td>
                    <td>
                      <label htmlFor='descriptionShortsv'>Kort beskrivning</label>
                    </td>
                    <td>
                      <textarea rows='4' id='descriptionShortsv' name='descriptionshort.sv' value={d.descriptionshort.sv || ""} onChange={(e) => inputHandler(e)}/>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label htmlFor='descriptionLong'>Long description</label>
                    </td>
                    <td>
                      <textarea rows='5' id='descriptionLong' name='descriptionlong.en' value={d.descriptionlong.en || ""} onChange={(e) => inputHandler(e)}/>
                    </td>
                    <td>
                      <label htmlFor='descriptionLongsv'>Lång beskrivning</label>
                    </td>
                    <td>
                      <textarea rows='5' id='descriptionLongsv' name='descriptionlong.sv' value={d.descriptionlong.sv || ""} onChange={(e) => inputHandler(e)}/>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label htmlFor='tasks'>Tasks</label>
                    </td>
                    <td>
                      <textarea title='Seperate multiple tasks by comma (,).' id='tasks' name='tasks.en' value={d.tasks.en || ""} onChange={(e) => inputHandler(e, true)}/>
                    </td>
                    <td>
                      <label htmlFor='taskssv'>Uppgifter</label>
                    </td>
                    <td>
                      <textarea title='Seperate multiple tasks by comma (,).' id='taskssv' name='tasks.sv' value={d.tasks.sv || ""} onChange={(e) => inputHandler(e, true)}/>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label htmlFor='softskills'>Softskills</label>
                    </td>
                    <td>
                      <textarea title='Seperate multiple skills by comma (,).' id='softskills' name='softskills.en' value={d.softskills.en || ""} onChange={(e) => inputHandler(e, true)}/>
                    </td>
                    <td>
                      <label htmlFor='softskillssv'>Softskills</label>
                    </td>
                    <td>
                      <textarea title='Seperate multiple skills by comma (,).' id='softskillssv' name='softskills.sv' value={d.softskills.sv || ""} onChange={(e) => inputHandler(e, true)}/>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ModalMainLanSpec>
            <ModalSubTitle>Non language specific</ModalSubTitle>
            <ModalMainCommon>
              <label htmlFor='client'>Client</label>
              <input type='text' id='client' name='client' value={d.client || ""} onChange={(e) => inputHandler(e)}/>
              <label htmlFor='regno'>Client reg. number</label>
              <input type='text' id='regno' name='regno' value={d.regno || ""} onChange={(e) => inputHandler(e)}/>
              <label htmlFor='location'>Google Location</label>
              <input type='text' id='location' name='location' value={d.location || ""} onChange={(e) => inputHandler(e)}/>
              <label htmlFor='lat'>Latitude</label>
              <input title='Decimals are seperated by dot (.).' type='text' id='lat' name='lat' value={d.coords[0] || ""} onChange={(e) => inputHandler(e)}/>
              <label htmlFor='long'>Longitude</label>
              <input title='Decimals are seperated by dot (.).' type='text' id='long' name='long' value={d.coords[1] || ""} onChange={(e) => inputHandler(e)}/>
              <label htmlFor='long'>Start date</label>
              <input title='ISO standard (YYYY-MM-DD).' type='date' id='startdate' name='startdate' value={d.startdate || ""} onChange={(e) => inputHandler(e)}/>
              <label htmlFor='long'>End date</label>
              <input title='ISO standard (YYYY-MM-DD).' type='date' id='enddate' name='enddate' value={d.enddate || ""} onChange={(e) => inputHandler(e)}/>
              <label htmlFor='tools'>Tools</label>
              <textarea  title='Seperate multiple tools by comma (,).' id='tools' name='tools' value={d.tools || ""} onChange={(e) => inputHandler(e, true)}/>
              <label htmlFor='programming'>Programming</label>
              <textarea title='Seperate multiple programming languages by comma (,).' id='programming' name='programming' value={d.programming || ""} onChange={(e) => inputHandler(e, true)}/>
            </ModalMainCommon>
          </ModalMain>
          <input type='submit'/>
        </ModalEditForm>
    <FaTrash title='Delete Assignment' onClick={() => deleteConfirmation('assignment')} />
      </ModalEditWrapper>
      </Modal.Body>
    </>
  )
}

export default EditModalAssignment
