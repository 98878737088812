import styled, {keyframes} from 'styled-components'

export const MobileNavMenu = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  background-color: #1e1e1e;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: popupAnimation 0.5s;

  ul > li {
    cursor: pointer;
  }
  ul {
    transform: translateX(-10%);
    list-style: none;
  }

  @keyframes popupAnimation {
    0% {scale:0};
    25% {scale:0.25, opacity: 1};
    50% {scale:0.5, opacity: 0.9};
    75% {scale: 0.75, opacity: 0.6};
    100% {scale: 1, opacity:0};
  }
`

export const MobileNavMenuClose = styled.div`
  position: fixed;
  bottom: 30px;
  right: 30px;
`
