import React, { useState } from 'react'
import { ModalEditForm, ModalEditWrapper} from './EditModalElements';
import {ModalMain, ModalMainLanSpec, ModalMainCommon, ModalSubTitle, ModalAddCourse} from '../AddModal/AddModalElements'
import AddModalEducationCourse from '../AddModal/ModalEducationCourse.js'
import EditModalEducationCourse from './EditModalEducationCourse';
import {FaPlus, FaTrash} from 'react-icons/fa';
import axios from 'axios';
import Modal from "react-bootstrap/Modal";
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import reqConfig from '../../app/vars';
import makeToast from '../../pages/Toaster.js';

const EditModalEducation = ({d, id, educations, setEducations, closeModal, openModal}) => {

  const token = localStorage.getItem('accessToken')

  const [edu, setEdu] = useState(d)

  const [courseElement, setCourseElement] = useState([]);

  const courses = {
    "code": "",
    "name": {
      "en": "",
      "sv": ""
    },
    "description": {
      "en": "",
      "sv": ""
    },
    "credits": "",
    "grade": "",
    "date": "",
    "subparts": []
  }

  const inputHandler = (e) => {
    let eduCopy = {...edu};
    let tmp = e.target.name;
    let loc = tmp.split('.');

    /**
    possibilities handled by the following if statements:
    if subparts:
      course[num].subparts[subnum].key.language = value
      course[num].subparts[subnum].key = value
    if course:
      course[num].key.language = value
      course[num].key = value
    if lat or long special case:
      Special case for latitude and longitude
    if root:
      key.language = value
      key = value
     */

    if (tmp.includes('subparts')) {
      if (loc[loc.length-1] === 'en' || loc[loc.length-1] === 'sv') {
        let course = loc[0].split('[')
        let num = course[1].split(']');
        let subcourse = loc[1].split('[')
        let subnum = subcourse[1].split(']')
        eduCopy[course[0]][num[0]][subcourse[0]][subnum[0]][loc[2]][loc[3]] = e.target.value;
      } else {
        let course = loc[0].split('[');
        let num = course[1].split(']');
        let subcourse = loc[1].split('[')
        let subnum = subcourse[1].split(']')
        eduCopy[course[0]][num[0]][subcourse[0]][subnum[0]][loc[2]] = e.target.value;
      }
    } else if(tmp.includes('course')) {
      if (loc[loc.length-1] === 'en' || loc[loc.length-1] === 'sv') {
        let course = loc[0].split('[');
        let num = course[1].split(']');
        eduCopy[course[0]][num[0]][loc[1]][loc[2]] = e.target.value
      } else {
        let course = loc[0].split('[');
        let num = course[1].split(']');
        eduCopy[course[0]][num[0]][loc[1]] = e.target.value
      }
    } else if(tmp === 'lat') {
      eduCopy['coords'][0] = Number(e.target.value)
    } else if (tmp === 'long') {
      eduCopy['coords'][1] = Number(e.target.value)
    } else {
      if (loc[loc.length-1] === 'en' || loc[loc.length-1] === 'sv') {
        eduCopy[loc[0]][loc[1]] = e.target.value
      } else {
        eduCopy[loc[0]] = e.target.value
      }
    }
    setEdu(eduCopy)
  }

  const modifyData = () => {
    let eduCopy = {...edu};
    setEdu(eduCopy);
  }


  const handleOnSubmit = (e) => {
    e.preventDefault();
    modifyData();
    updateEducation();
    closeModal()
  }

  const deleteConfirmation = (type, cid = undefined, scid = undefined) => {
    closeModal();
    confirmAlert({
      title: 'Confirm before submit',
      message: `Are you sure you want to delete this ${type} with education id ${d.educationid}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            switch (type) {
              case 'education':
                deleteEducation()
                deleteDocument()
                break;
              case 'course':
                deleteCourse(cid)
                break;
              case 'subpart':
                deleteSubPart(cid, scid);
                break;
              default:
                console.log('nothing happened')
                break;
            }}
        },
        {
          label: 'No',
          onClick: () => console.log('aborted')
        }
      ]
    })
  }

  const deleteEducation = () => {
    let dataCopy = [...educations]
    let k = 0
    for (let i = 0; i <= dataCopy.length; i++) {
      if (dataCopy[i].educationid === d.educationid) {
        break;
      }
      k++;
    }
    if (k > dataCopy.length) {
      return console.error('something is wrong');
    }
    dataCopy.splice(k, 1);
    setEdu(dataCopy)
    setEducations(dataCopy)
  }

  const deleteCourse = (cid) => {
    console.log('deleting course')
    let dataCopy = [...edu]
    let k = 0
    for (let i = 0; i <= dataCopy.length; i++) {
      if (dataCopy[i].educationid === d.educationid) {
        break;
      }
      k++;
    }
    if (k > dataCopy.length) {
      return console.error('something is wrong');
    }
    dataCopy[k].course.splice(cid, 1)
    setEdu(dataCopy)
    updateEducation()
    openModal()
  }

  const deleteSubPart = (cid, scid) => {
    console.log('deleting subpart')
    let dataCopy = [...edu]
    let k = 0
    for (let i = 0; i <= dataCopy.length; i++) {
      if (dataCopy[i].educationid === d.educationid) {
        break;
      }
      k++;
    }
    if (k > dataCopy.length) {
      return console.error('something is wrong');
    }
    dataCopy[k].course[cid].subparts.splice(scid, 1)
    setEdu(dataCopy)
    updateEducation()
    openModal()
  }
  
  const addCourse = () => {
    let cid = edu.course.length;
    setCourseElement(courseElement.concat(<AddModalEducationCourse i={cid || 0} key={cid || 0} cid={cid || 0} inputHandler={inputHandler} edu={edu} setEdu={setEdu} />))
    let eduCopy = {...edu}
    eduCopy.course.push(courses)
    setEdu(eduCopy)
  }

  // TODO: Add deleteEducation function

  const updateEducation = () => {
    const uA = async () => {
      await axios.patch(
        '/api/v1/dashboard/edit/education', 
        edu,
        reqConfig(token)
      )
        .then(response => {
          makeToast('success', 'Update Successful')
        })
        .catch(err => console.error(err));
    }

    uA();
  }

  const deleteDocument = () => {
    const dD = async () => {
      await axios.delete(
        '/api/v1/dashboard/edit/education/' + d._id,
        reqConfig(token)
      )
      .then(response => {
        makeToast('success', 'Deletion successful')
      })
      .catch(err => console.log(err));
    }

    dD();
  }

  return (
    <>
    <Modal.Header closeButton>
      <Modal.Title>Edit Education ID {edu.educationid}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <ModalEditWrapper>
        <ModalEditForm onSubmit={(e) => handleOnSubmit(e)}>
          <ModalMain>
          <ModalSubTitle>Language Specific</ModalSubTitle>
            <ModalMainLanSpec>
              <table>
                <thead>
                  <tr>
                    <th>Property</th><th>Content</th><th>Egenskap</th><th>Innehåll</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <label htmlFor={'school'}>School:</label> 
                    </td>
                    <td>
                      <input title='Add multiple titles by seperating them with ,' type='text' id={'school'} name='school.en' value={d.school.en} onChange={(e) => inputHandler(e)}/>
                    </td>
                    <td>
                      <label htmlFor={'schoolsv'}>Skola:</label> 
                    </td>
                    <td>
                      <input title='Add multiple titles by seperating them with ,' type='text' id={'schoolsv'} name='school.sv' value={d.school.sv} onChange={(e) => inputHandler(e)}/>
                    </td>
                  </tr>
                  {d.programme &&
                  <tr>
                    <td>
                      <label htmlFor={'programme'}>Programme:</label> 
                    </td>
                    <td>
                      <input title='' type='text' id={'programme'} name='programme.en' value={d.programme.en} onChange={(e) => inputHandler(e)}/>
                    </td>
                    <td>
                      <label htmlFor={'programmesv'}>Program:</label> 
                    </td>
                    <td>
                      <input title='' type='text' id={'programmesv'} name='programme.sv' value={d.programme.sv} onChange={(e) => inputHandler(e)}/>
                    </td>
                  </tr>
                  }
                  {d.degree &&
                  <tr>
                    <td>
                      <label htmlFor={'degree'}>Degree:</label> 
                    </td>
                    <td>
                      <input title='' type='text' id={'degree'} name='degree.en' value={d.degree.en} onChange={(e) => inputHandler(e)}/>
                    </td>
                    <td>
                      <label htmlFor={'degreesv'}>Examen:</label> 
                    </td>
                    <td>
                      <input title='' type='text' id={'degreesv'} name='degree.sv' value={d.degree.sv} onChange={(e) => inputHandler(e)}/>
                    </td>
                  </tr>
                  }
                  <tr>
                    <td>
                      <label htmlFor={'description'}>Description:</label> 
                    </td>
                    <td>
                      <textarea rows='4' id={'description'} name='description.en' value={d.description.en} onChange={(e) => inputHandler(e)}/>
                    </td>
                    <td>
                      <label htmlFor={'descriptionsv'}>Beskrivning:</label> 
                    </td>
                    <td>
                      <textarea rows='4' id={'descriptionsv'} name='description.sv' value={d.description.sv} onChange={(e) => inputHandler(e)}/>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ModalMainLanSpec>
            <ModalSubTitle>Non language specific</ModalSubTitle>
            <ModalMainCommon>
              <label htmlFor='location'>Google Location</label>
              <input type='text' id='location' name='location' value={edu.location} onChange={(e) => inputHandler(e)}/>
              <label htmlFor='lat'>Latitude</label>
              <input title='Decimals are seperated by dot (.).' type='number' id='lat' name='lat' value={edu.coords[0]} onChange={(e) => inputHandler(e)}/>
              <label htmlFor='long'>Longitude</label>
              <input title='Decimals are seperated by dot (.).' type='number' id='long' name='long' value={edu.coords[1]} onChange={(e) => inputHandler(e)}/>
              <label htmlFor='long'>Start date</label>
              <input title='ISO standard (YYYY-MM-DD).' type='date' id='startdate' name='startdate' value={edu.startdate} onChange={(e) => inputHandler(e)}/>
              <label htmlFor='long'>End date</label>
              <input title='ISO standard (YYYY-MM-DD).' type='date' id='enddate' name='enddate' value={edu.enddate} onChange={(e) => inputHandler(e)}/>
            </ModalMainCommon>
    {/* TOTO: Add NAV för edu with more than one course */}
              {edu.course.map((c, i) => 
                (
                  <EditModalEducationCourse
                    i={i}
                    key={i}
                    cid={i}
                    inputHandler={inputHandler}
                    edu={edu}
                    setEdu={setEdu}
                    c={c}
                    id={id}
                    deleteConfirmation={deleteConfirmation}
                  />
                )
              )}
              <ModalAddCourse onClick={() =>  {
                addCourse()
              }}><FaPlus/>Add Course</ModalAddCourse>
          </ModalMain>
          <input type='submit'/>
        </ModalEditForm>
    <FaTrash title='Delete Education' onClick={() => deleteConfirmation('education')}/>
      </ModalEditWrapper>
      </Modal.Body>
    </>
  )
}

export default EditModalEducation
