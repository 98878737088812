import React, { useState } from 'react'
import { ModalEditForm, ModalEditWrapper } from '../EditModal/EditModalElements';
import {ModalMain, ModalMainLanSpec, ModalMainCommon, ModalSubTitle, ModalAddCourse} from './AddModalElements'
import axios from 'axios';
import Modal from "react-bootstrap/Modal";
import { FaPlus } from 'react-icons/fa';
import ModalEducationCourse from './ModalEducationCourse';
import reqConfig from '../../app/vars';
import makeToast from '../../pages/Toaster';

const AddModalEducation = ({lastid, educations, setEducations, closeModal}) => {

  const token = localStorage.getItem('accessToken')

  const [edu, setEdu] = useState({
    "school": {
      "en": "",
      "sv": ""
    },
    "programme": {
      "en": "",
      "sv": ""
    },
    "degree": {
      "en": "",
      "sv": ""
    },
    "description": {
      "en": "",
      "sv": ""
    },
    "location": "",
    "coords": [],
    "startdate": "",
    "enddate": "",
    "course": []
  })

  const courses = {
    "code": "",
    "name": {
      "en": "",
      "sv": ""
    },
    "description": {
      "en": "",
      "sv": ""
    },
    "credits": "",
    "grade": "",
    "date": "",
    "subparts": []
  }

  const [courseElement, setCourseElement] = useState([])
  
  const inputHandler = (e) => {
    let eduCopy = {...edu};
    let tmp = e.target.name;
    let loc = tmp.split('.');

    /**
    possibilities handled by the following if statements:
    if subparts:
      course[num].subparts[subnum].key.language = value
      course[num].subparts[subnum].key = value
    if course:
      course[num].key.language = value
      course[num].key = value
    if lat or long special case:
      Special case for latitude and longitude
    if root:
      key.language = value
      key = value
     */

    if (tmp.includes('subparts')) {
      if (loc[loc.length-1] === 'en' || loc[loc.length-1] === 'sv') {
        let course = loc[0].split('[')
        let num = course[1].split(']');
        let subcourse = loc[1].split('[')
        let subnum = subcourse[1].split(']')
        eduCopy[course[0]][num[0]][subcourse[0]][subnum[0]][loc[2]][loc[3]] = e.target.value;
      } else {
        let course = loc[0].split('[');
        let num = course[1].split(']');
        let subcourse = loc[1].split('[')
        let subnum = subcourse[1].split(']')
        eduCopy[course[0]][num[0]][subcourse[0]][subnum[0]][loc[2]] = e.target.value;
      }
    } else if(tmp.includes('course')) {
      if (loc[loc.length-1] === 'en' || loc[loc.length-1] === 'sv') {
        let course = loc[0].split('[');
        let num = course[1].split(']');
        eduCopy[course[0]][num[0]][loc[1]][loc[2]] = e.target.value
      } else {
        let course = loc[0].split('[');
        let num = course[1].split(']');
        eduCopy[course[0]][num[0]][loc[1]] = e.target.value
      }
    } else if(tmp === 'lat') {
      eduCopy['coords'][0] = Number(e.target.value)
    } else if (tmp === 'long') {
      eduCopy['coords'][1] = Number(e.target.value)
    } else {
      if (loc[loc.length-1] === 'en' || loc[loc.length-1] === 'sv') {
        eduCopy[loc[0]][loc[1]] = e.target.value
      } else {
        eduCopy[loc[0]] = e.target.value
      }
    }

    setEdu(eduCopy)
  }

  const modifyData = () => {
    let eduCopy = {...edu};
    let eduMainCopy = [...educations]

    /**
      * Remove programme and degree if strings are empty
      *
      */
    if (eduCopy.programme.en === "" && eduCopy.programme.sv === "") {
      console.log("deleting programme and degree keys")
      delete eduCopy.programme
      delete eduCopy.degree
    }
    eduMainCopy.push(eduCopy)
    setEdu(eduCopy);
    setEducations(eduMainCopy)
  }

  const handleOnSubmit = (e) => {
    e.preventDefault();
    modifyData();
    updateEducation();
    closeModal()
  }

  const updateEducation = () => {
    const uA = async () => {
      await axios.post(
        '/api/v1/dashboard/edit/education', edu, reqConfig(token) 
      )
        .then(response => {
          makeToast('success', 'Education added')
        })
        .catch(err => {
          makeToast('error', err.data.msg)
          let eduMainCopy = [...educations]
          setEducations(eduMainCopy)
        })
    }

    uA();
  }
  // TODO: Add deleteCourse function

  const addCourse = () => {
    let cid = edu.course.length;
    setCourseElement(courseElement.concat(<ModalEducationCourse i={cid || 0} key={cid || 0} cid={cid || 0} inputHandler={inputHandler} edu={edu} setEdu={setEdu} />))
    let eduCopy = {...edu}
    eduCopy.course.push(courses)
    setEdu(eduCopy)
  }

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Create education</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <ModalEditWrapper>
        <ModalEditForm onSubmit={(e) => handleOnSubmit(e)}>
          <ModalMain>
          <ModalSubTitle>Language Specific</ModalSubTitle>
            <ModalMainLanSpec>
              <table>
                <thead>
                  <tr>
                    <th>Property</th><th>Content</th><th>Egenskap</th><th>Innehåll</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <label htmlFor={'school'}>School:</label> 
                    </td>
                    <td>
                      <input title='Add multiple titles by seperating them with ,' type='text' id={'school'} name='school.en' onChange={(e) => inputHandler(e)}/>
                    </td>
                    <td>
                      <label htmlFor={'schoolsv'}>Skola:</label> 
                    </td>
                    <td>
                      <input title='Add multiple titles by seperating them with ,' type='text' id={'schoolsv'} name='school.sv' onChange={(e) => inputHandler(e)}/>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label htmlFor={'programme'}>Programme:</label> 
                    </td>
                    <td>
                      <input title='' type='text' id={'programme'} name='programme.en' onChange={(e) => inputHandler(e)}/>
                    </td>
                    <td>
                      <label htmlFor={'programmesv'}>Program:</label> 
                    </td>
                    <td>
                      <input title='' type='text' id={'programmesv'} name='programme.sv' onChange={(e) => inputHandler(e)}/>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label htmlFor={'degree'}>Degree:</label> 
                    </td>
                    <td>
                      <input title='' type='text' id={'degree'} name='degree.en' onChange={(e) => inputHandler(e)}/>
                    </td>
                    <td>
                      <label htmlFor={'degreesv'}>Examen:</label> 
                    </td>
                    <td>
                      <input title='' type='text' id={'degreesv'} name='degree.sv' onChange={(e) => inputHandler(e)}/>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label htmlFor={'description'}>Description:</label> 
                    </td>
                    <td>
                      <input title='' type='text' id={'description'} name='description.en' onChange={(e) => inputHandler(e)}/>
                    </td>
                    <td>
                      <label htmlFor={'descriptionsv'}>Beskrivning:</label> 
                    </td>
                    <td>
                      <input title='' type='text' id={'descriptionsv'} name='description.sv' onChange={(e) => inputHandler(e)}/>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ModalMainLanSpec>
            <ModalSubTitle>Non language specific</ModalSubTitle>
            <ModalMainCommon>
              <label htmlFor='location'>Google Location</label>
              <input type='text' id='location' name='location' onChange={(e) => inputHandler(e)}/>
              <label htmlFor='lat'>Latitude</label>
              <input title='Decimals are seperated by dot (.).' type='number' id='lat' name='lat' onChange={(e) => inputHandler(e)}/>
              <label htmlFor='long'>Longitude</label>
              <input title='Decimals are seperated by dot (.).' type='number' id='long' name='long' onChange={(e) => inputHandler(e)}/>
              <label htmlFor='long'>Start date</label>
              <input title='ISO standard (YYYY-MM-DD).' type='date' id='startdate' name='startdate' onChange={(e) => inputHandler(e)}/>
              <label htmlFor='long'>End date</label>
              <input title='ISO standard (YYYY-MM-DD).' type='date' id='enddate' name='enddate' onChange={(e) => inputHandler(e)}/>
            </ModalMainCommon>
              { courseElement }
              <ModalAddCourse onClick={() =>  {
                addCourse() 
              }}><FaPlus/>Add Course</ModalAddCourse>
          </ModalMain>
          <input type='submit'/>
        </ModalEditForm>
      </ModalEditWrapper>
    </Modal.Body>
    </>
  )
}

export default AddModalEducation

