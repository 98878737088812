import React, {useState} from "react";
import EditModalEducationCourseSubPart from './EditModalEducationSubPart.js'
import AddModalEducationCourseSubPart from '../AddModal/ModalEducationCourseSubPart.js'
import {FaPlus, FaTrash} from 'react-icons/fa'
import {ModalMainLanSpec, ModalMainCommon, ModalAddCourseSubPart } from '../AddModal/AddModalElements'
import {ModalTop} from './EditModalElements.js'

const EditModalEducationCourse = ({i, id, inputHandler, cid, edu, setEdu, c, deleteConfirmation}) => {

  const subParts = {
    "code": "",
    "name": {
      "en": "",
      "sv": ""
    },
    "description": {
      "en": "",
      "sv": ""
    },
    "credits": "",
    "grade": "",
    "date": ""
  };

  const [subCourseElement, setSubCourseElement] = useState([])
  
  const addSubCourse = (cid) => {
    let scid = edu.course[cid].subparts.length
    setSubCourseElement(subCourseElement.concat(<AddModalEducationCourseSubPart i={scid} key={scid || 0} scid={scid || 0} cid={cid || 0} inputHandler={inputHandler} edu={edu} setEdu={setEdu} />))
    let eduCopy = {...edu}
    eduCopy.course[cid].subparts.push(subParts)
    setEdu(eduCopy)
  }

  return (
    <>
    <ModalMainLanSpec id={`id${c.name.en}`}>
    <ModalTop>
    <span>Course {cid+1}</span><span><FaTrash title='Delete Course' onClick={() => deleteConfirmation('course', cid)} /></span>
    </ModalTop>
      <table>
        <thead>
          <tr>
            <th>Property</th><th>Content</th><th>Egenskap</th><th>Innehåll</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <label htmlFor={'coursename' + i}>Course name:</label>
            </td>
            <td>
              <input title='' type='text' id={'coursename' + i} name={`course[${i}].name.en`} value={c.name.en} onChange={(e) => inputHandler(e)} />
            </td>
            <td>
              <label htmlFor={'coursenamesv' + i}>Kursnamn:</label>
            </td>
            <td>
              <input title='' type='text' id={'coursenamesv' + i} name={`course[${i}].name.sv`} value={c.name.sv} onChange={(e) => inputHandler(e)} />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor={'description' + i}>Description:</label>
            </td>
            <td>
              <textarea rows='4' id={'description' + i} name={`course[${i}].description.en`} value={c.description.en} onChange={(e) => inputHandler(e)} />
            </td>
            <td>
              <label htmlFor={'descriptionsv' + i}>Beskrivning:</label>
            </td>
            <td>
              <textarea rows='4' id={'descriptionsv' + i} name={`course[${i}].description.sv`} value={c.description.sv} onChange={(e) => inputHandler(e)} />
            </td>
          </tr>
        </tbody>
      </table>
    </ModalMainLanSpec>
    <ModalMainCommon>
        <label htmlFor={'coursecode' + i}>Course Code:</label>
        <input type='text' id={'coursecode' + i} name={`course[${i}].code`} value={c.code} onChange={(e) => inputHandler(e)} />
        <label htmlFor={'coursecredits' + i}>Course credits:</label>
        <input type='text' id={'coursecredits' + i} name={`course[${i}].credits`} value={c.credits} onChange={(e) => inputHandler(e)} />
        <label htmlFor={'coursegrade' + i}>Course grade:</label>
        <input type='text' id={'coursegrade' + i} name={`course[${i}].grade`} value={c.grade} onChange={(e) => inputHandler(e)} />
        <label htmlFor={'coursedate' + i}>Course finish date:</label>
        <input type='date' id={'coursedate' + i} name={`course[${i}].date`} value={c.date || undefined} onChange={(e) => inputHandler(e)} />
    </ModalMainCommon>
    {edu.course[cid].subparts.map((sp, i) => (
      <EditModalEducationCourseSubPart
      i={i}
      key={i}
      cid={cid}
      scid={i}
      sp={sp}
      inputHandler={inputHandler}
      deleteConfirmation={deleteConfirmation}
      />
    ))}
      <ModalAddCourseSubPart onClick={() => addSubCourse(cid)}><FaPlus />Add Course Sub Part</ModalAddCourseSubPart>
    </>
  )
}

export default EditModalEducationCourse
