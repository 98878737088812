import React, {useState} from "react";
import ModalEducationCourseSubPart from "./ModalEducationCourseSubPart";
import {FaPlus} from 'react-icons/fa'
import {ModalMainLanSpec, ModalMainCommon, ModalAddCourseSubPart } from './AddModalElements'

const ModalEducationCourse = ({i, inputHandler, cid, edu, setEdu}) => {

  const subParts = {
    "code": "",
    "name": {
      "en": "",
      "sv": ""
    },
    "description": {
      "en": "",
      "sv": ""
    },
    "credits": "",
    "grade": "",
    "date": ""
  };

  const [subCourseElement, setSubCourseElement] = useState([])
  
  const addSubCourse = (cid) => {
    let scid = edu.course[cid].subparts.length
    setSubCourseElement(subCourseElement.concat(<ModalEducationCourseSubPart i={scid} key={scid || 0} scid={scid || 0} cid={cid || 0} inputHandler={inputHandler}  />))
    let eduCopy = {...edu}
    eduCopy.course[cid].subparts.push(subParts)
    setEdu(eduCopy)
  }
  return (
    <>
    <ModalMainLanSpec>
    <p>Course {cid+1}</p>
      <table>
        <thead>
          <tr>
            <th>Property</th><th>Content</th><th>Egenskap</th><th>Innehåll</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <label htmlFor={'coursename' + i}>Course name:</label>
            </td>
            <td>
              <input title='' type='text' id={'coursename' + i} name={`course[${i}].name.en`} onChange={(e) => inputHandler(e)} />
            </td>
            <td>
              <label htmlFor={'coursenamesv' + i}>Kursnamn:</label>
            </td>
            <td>
              <input title='' type='text' id={'coursenamesv' + i} name={`course[${i}].name.sv`} onChange={(e) => inputHandler(e)} />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor={'description' + i}>Description:</label>
            </td>
            <td>
              <input title='' type='text' id={'description' + i} name={`course[${i}].description.en`} onChange={(e) => inputHandler(e)} />
            </td>
            <td>
              <label htmlFor={'descriptionsv' + i}>Beskrivning:</label>
            </td>
            <td>
              <input title='' type='' id={'descriptionsv' + i} name={`course[${i}].description.sv`} onChange={(e) => inputHandler(e)} />
            </td>
          </tr>
        </tbody>
      </table>
    </ModalMainLanSpec>
    <ModalMainCommon>
        <label htmlFor={'coursecode' + i}>Course Code:</label>
        <input type='text' id={'coursecode' + i} name={`course[${i}].code`} onChange={(e) => inputHandler(e)} />
        <label htmlFor={'coursecredits' + i}>Course credits:</label>
        <input type='text' id={'coursecredits' + i} name={`course[${i}].credits`} onChange={(e) => inputHandler(e)} />
        <label htmlFor={'coursegrade' + i}>Course grade:</label>
        <input type='text' id={'coursegrade' + i} name={`course[${i}].grade`} onChange={(e) => inputHandler(e)} />
        <label htmlFor={'coursedate' + i}>Course finish date:</label>
        <input type='date' id={'coursedate' + i} name={`course[${i}].date`} onChange={(e) => inputHandler(e)} />
    </ModalMainCommon>
      {subCourseElement}
      <ModalAddCourseSubPart onClick={() => addSubCourse(cid)}><FaPlus />Add Course Sub Part</ModalAddCourseSubPart>
    </>
  )
}

export default ModalEducationCourse
