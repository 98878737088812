import styled from 'styled-components'

export const ModalEditWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ModalEditTopLine = styled.h1`
  text-transform: uppercase;
  font-size: 0.7rem;
`

export const ModalEditForm = styled.form`
`

export const ModalEditInputGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 1px dashed black;

  div{
    font-size: 0.6rem;
    font-weight: bolder;
  }

  label {
    font-size: 0.6rem;
    margin-right: 10px;
  }

  input, textarea{
    display: inline;
    border: none;
    font-family: inherit;
    font-size: inherit;
    padding: none;
    font-size: 0.6rem;
    background-color: inherit;
  }

  textarea {
    display: inline-block;
    min-height: 20px;
    min-width: 20em;
  }

`

export const ModalStickyNavBar = styled.nav`
  display: flex;
  flex: row wrap;
  color: #fff;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    background-color: #333;
  }
  ul > li {
    display: inline;
  }

  li {
    font-size: 0.5rem;
    display: block;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    cursor: pointer;
  }

  li:hover {
    background-color: #111;
  }
`

export const ModalTop = styled.div`
  display: flex;
  flex: row no-wrap;
  justify-content: space-between;
`
