import React from 'react'
import InfoModal from '../InfoModal'
import { useState } from 'react';
import {FaEdit} from 'react-icons/fa'
import { stringToArray } from '../../app/helperFunctions.js'
import EditModal from '../EditModal/';
import Modal from "react-bootstrap/Modal";
import {monthDiff } from '../../app/helperFunctions';
import { CommonContainer, CommonDates, CommonDescription, CommonDistance, CommonDuration, CommonEdit, CommonEndDate, CommonLeft, CommonLocation, CommonMiddle, CommonMidTitle, CommonPlace, CommonRole, CommonStartDate, CommonWrapper } from '../CommonElements/CommonElements';

const Assignments = ({data, setAssignments, assignments, id, isLoggedIn, l, lan}) => {

  // Main info
  // Strings
  const name = data.name[lan];
  const client = data.client;
  const start = new Date(data.startdate.split('-'));
  const end = new Date(data.enddate.split('-'));
  const descriptionshort = data.descriptionshort[lan];
  const location = data.location;
  const months = monthDiff(start, end, lan);

  // Arrays
  var roles = stringToArray(data.roles[lan][0]);

  const [modal, setModal] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const openModal = () => {
    setModal(true);
  }

  const closeModal = () => {
    setModal(false)
  }


  return (
    <>
      <CommonContainer
        onMouseEnter={() => isLoggedIn ? setShowEdit(true) : setShowEdit(false)}
        onMouseLeave={() => setShowEdit(false)}
      >
        <CommonWrapper>
          <CommonLeft onClick={() => {
            setInfoModal(true);
            setEditModal(false);
            openModal();
          }}>
            <CommonRole>{roles}</CommonRole>
            <CommonPlace>{client}</CommonPlace>
            <CommonLocation>{location.split(' ').slice(1)}</CommonLocation>
            <CommonDates>
              <CommonStartDate>
                { l && (
                  start.toLocaleString('en-EN', {month: 'short', year:'numeric'})
                )}
                { !l && (
                  start.toLocaleString('sv-SE', {month: 'short', year:'numeric'})
                )}
              </CommonStartDate>
               - 
              <CommonEndDate>
                { l && (
                  end.toLocaleString('en-EN', {month: 'short', year:'numeric'})
                )}
                { !l && (
                  end.toLocaleString('sv-SE', {month: 'short', year:'numeric'})
                )}
               </CommonEndDate>
              <CommonDuration>{months}</CommonDuration>
            </CommonDates>
          </CommonLeft>
          <CommonMiddle onClick={() => {
            setInfoModal(true);
            setEditModal(false);
            openModal();
          }}>
            <CommonMidTitle>{name}</CommonMidTitle>
            <CommonDescription>{descriptionshort}</CommonDescription>
          </CommonMiddle>
          {showEdit && (
            <CommonEdit>
              <FaEdit onClick={() => {
                setEditModal(true);
                setInfoModal(false)
                openModal();
              }}/>
            </CommonEdit>
          )}
        </CommonWrapper>
        <Modal
          size="lg"
          show={modal}
          onHide={() => setModal(false)}
        >
          {editModal && (
            <EditModal 
            type='assignment' 
            d={data} 
            id={id} 
            data={assignments} 
            setAssignments={setAssignments} 
            openModal={openModal}
            closeModal={closeModal}
            />
          )}
          {infoModal && (
            <InfoModal type='assignment' d={data} topline={data.name[lan]} l={l} lan={lan} />
          )}
        </Modal>
      </CommonContainer>
    </>
  )
}

export default Assignments
