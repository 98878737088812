import React from 'react'
import AddModalAssignments from './AddModalAssignment.js'
import AddModalEmployments from './AddModalEmployment.js'
import AddModalEducations from './AddModalEducation.js'
import AddModalCertificate from './AddModalCertificate.js'
import { ModalContainer, ModalWrapper, ModalDetails } from '../InfoModal/ModalElements'

const AddModal = ({type, lastid, topline, closeModal, assignments, data, setData}) => {

  const renderSwitch = (type, lastid) => {
    switch(type) {
      case 'assignment':
        return <AddModalAssignments lastid={lastid} assignments={data} setAssignments={setData} closeModal={closeModal}/>
      case 'employment':
        return <AddModalEmployments lastid={lastid} assignments={assignments} employments={data} setEmployments={setData} closeModal={closeModal}/>
      case 'education':
        return <AddModalEducations lastid={lastid} educations={data} setEducations={setData} closeModal={closeModal}/>
      case 'certificate':
        return <AddModalCertificate lastid={lastid} certificates={data} setCertificates={setData} closeModal={closeModal}/>
      default:
        <div></div>
        break;
    }
  }

  return (
    <>
      <ModalContainer>
        <ModalWrapper>
          <ModalDetails>
          {renderSwitch(type, lastid)}
          </ModalDetails>
        </ModalWrapper>
      </ModalContainer>
    </>
  )
}

export default AddModal
