import React, { useState } from 'react'
import { ModalEditForm, ModalEditWrapper } from './EditModalElements';
import {ModalMain, ModalMainLanSpec, ModalMainCommon, ModalSubTitle} from '../AddModal/AddModalElements'
import axios from 'axios';
import { stringToArray } from '../../app/helperFunctions.js'
import Modal from "react-bootstrap/Modal";
import { FaTrash } from 'react-icons/fa';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import reqConfig from '../../app/vars';
import makeToast from '../../pages/Toaster';

const EditModalEmployment = ({d, id, employments, setEmployments, closeModal}) => {

  const token = localStorage.getItem('accessToken')

  const [emp, setEmp] = useState(employments)

  const inputHandler = (e) => {
    let empCopy = {...emp};
    let tmp = e.target.name;
    let tmp2 = tmp.split('.');
    if (tmp2[tmp2.length-1] === 'en' || tmp2[tmp2.length-1] === 'sv') {
      empCopy[id][tmp2[0]][tmp2[1]] = e.target.value;
    } else if (tmp === 'lat') {
      empCopy[id]['coords'][0] = Number(e.target.value);
    } else if (tmp === 'long') {
      empCopy[id]['coords'][1] = Number(e.target.value);
    } else {
      empCopy[id][tmp2[0]] = e.target.value;
    }
    setEmp(empCopy)
  }

  const modifyData = () => {
    let empCopy = {...emp};
    empCopy[id].title.en = stringToArray(empCopy[id].title.en);
    empCopy[id].title.sv = stringToArray(empCopy[id].title.sv);
    empCopy[id].assignmentid = stringToArray(empCopy[id].assignmentid)
    empCopy[id].assignmentid.map((aid, i) => {
      return empCopy[id].assignmentid[i] = Number(aid)
    })
    if (empCopy[id].startdate === "-") {
      empCopy[id].startdate = ""
    }
    if (empCopy[id].enddate === "-") {
      empCopy[id].enddate = ""
    }
    setEmp(empCopy);
  }


  const handleOnSubmit = (e) => {
    e.preventDefault();
    modifyData();
    closeModal();
    updateEmployment();
  }

  const deleteConfirmation = (type, cid = undefined, scid = undefined) => {
    closeModal();
    confirmAlert({
      title: 'Confirm before submit',
      message: `Are you sure you want to delete this ${type}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            switch (type) {
              case 'employment':
                deleteEmployment()
                deleteDocument()
                break;
              default:
                console.log('nothing happened')
                break;
            }}
        },
        {
          label: 'No',
          onClick: () => console.log('aborted')
        }
      ]
    })
  }

  const deleteEmployment = () => {
    let dataCopy = [...employments]
    let k = 0
    for (let i = 0; i <= dataCopy.length; i++) {
      if (dataCopy[i].employmentid === d.employmentid) {
        break;
      }
      k++;
    }
    if (k > dataCopy.length) {
      return console.error('something is wrong');
    }
    dataCopy.splice(k, 1);
    setEmployments(dataCopy)
  }

  const deleteDocument = () => {
    const dD = async () => {
      await axios.delete(
        '/api/v1/dashboard/edit/employment/' + d._id,
        reqConfig(token)
      )
      .then(response => {
        makeToast('success', 'Deletion successful')
      })
      .catch(err => console.log(err));
    }

    dD();
  }

  const updateEmployment = () => {
    const uA = async () => {
      await axios.patch(
        '/api/v1/dashboard/edit/employment', 
        d,
        reqConfig(token)
      )
        .then(response => {
          makeToast('success', 'Update successful');
        })
        .catch(err => makeToast('error', err.data.msg));
    }

    uA();
  }

  const a = employments[id];

  return (
    <>
    <Modal.Header closeButton>
      <Modal.Title>Edit employment ID {a.employmentid}<br/>{a.company}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <ModalEditWrapper>
        <ModalEditForm onSubmit={(e) => handleOnSubmit(e)}>
          <ModalMain>
          <ModalSubTitle>Language Specific</ModalSubTitle>
            <ModalMainLanSpec>
              <table>
                <thead>
                  <tr>
                    <th>Property</th><th>Content</th><th>Egenskap</th><th>Innehåll</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <label htmlFor={'title'}>Title:</label> 
                    </td>
                    <td>
                      <input title='Add multiple titles by seperating them with ,' type='text' id={'title'} name='title.en' value={d.title.en || undefined} onChange={(e) => inputHandler(e)}/>
                    </td>
                    <td>
                      <label htmlFor={'titlesv'}>Titel:</label> 
                    </td>
                    <td>
                      <input title='Add multiple titles by seperating them with ,' type='text' id={'titlesv'} name='title.sv' value={d.title.sv || undefined} onChange={(e) => inputHandler(e)}/>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ModalMainLanSpec>
            <ModalSubTitle>Non language specific</ModalSubTitle>
            <ModalMainCommon>
              <label htmlFor='company'>Company</label>
              <input type='text' id='company' name='company' value={d.company || ""} onChange={(e) => inputHandler(e)}/>
              <label htmlFor='regno'>Company reg. number</label>
              <input type='text' id='regno' name='regno' value={d.regno || ""} onChange={(e) => inputHandler(e)}/>
              <label htmlFor='location'>Google Location</label>
              <input type='text' id='location' name='location' value={d.location || ""} onChange={(e) => inputHandler(e)}/>
              <label htmlFor='lat'>Latitude</label>
              <input title='Decimals are seperated by dot (.).' type='text' id='lat' name='lat' value={d.coords[0] || ""} onChange={(e) => inputHandler(e)}/>
              <label htmlFor='long'>Longitude</label>
              <input title='Decimals are seperated by dot (.).' type='text' id='long' name='long' value={d.coords[1] || ""} onChange={(e) => inputHandler(e)}/>
              <label htmlFor='long'>Start date</label>
              <input title='ISO standard (YYYY-MM-DD).' type='date' id='startdate' name='startdate' value={d.startdate || ""} onChange={(e) => inputHandler(e)}/>
              <label htmlFor='long'>End date</label>
              <input title='ISO standard (YYYY-MM-DD).' type='date' id='enddate' name='enddate' value={d.enddate || ""} onChange={(e) => inputHandler(e)}/>
              <label htmlFor='assignmentids'>Associated assigments</label>
              <input title='Seperate each ID with ,' type='text' id='assignmentids' name='assignmentid' value={d.assignmentid || ""} onChange={(e) => inputHandler(e)}/>
            </ModalMainCommon>
          </ModalMain>
          <input type='submit'/>
        </ModalEditForm>
    <FaTrash title='Delete Employment' onClick={() => deleteConfirmation('employment')} />
      </ModalEditWrapper>
      </Modal.Body>
    </>
  )
}

export default EditModalEmployment
