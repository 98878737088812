/**
 * 
 * @param {Date} start 
 * @param {Date} end 
 * @returns The duration between two dates in weeks.
 */
const weekDiff = (start, end) => {
  var diff = (end.getTime() - start.getTime()) / 1000;
  diff /= (60 * 60 * 24 * 7);
  return Math.abs(Math.round(diff))
}

/**
 * Takes two string represenatation of dates, e.g. "2020-10-30", which in other words is 30th of October, 2020, and gives the duraton between these two in various ways, but weeks being the lower limit.
 * @param {Date} start 
 * @param {Date} end 
 * @returns The duration between two dates in (years,) months and/or weeks.
 */
export const monthDiff = (start, end, lan = 'en') => {
  if (isNaN(Date.parse(end))) {
    end = new Date();
  }
  var diffMonths = (end.getFullYear() - start.getFullYear()) * 12;
  diffMonths -= start.getMonth()
  diffMonths += end.getMonth();
  if (isNaN(diffMonths)){
    return 'TBD'
  } else if (diffMonths <= 0) {
    var w = weekDiff(start, end);
    if (lan==='en') {
      return w > 1 ? w + ' weeks' : w + ' week'
    } else {
      return w > 1 ? w + ' veckor' : w + ' vecka'
    }
  } else {
    if (diffMonths > 12){
      var y = Math.floor(diffMonths/12);
      var m = diffMonths % 12;
      if (lan==='en') {
        var yt = y > 1 ? 'years' : 'year';
        var mt = m > 1 ? 'months' : 'month';
        return y + ' ' + yt + ' and ' + m + ' ' + mt;
      } else {
        var yt = 'år';
        var mt = m > 1 ? 'månader' : 'månad';
        return y + ' ' + yt + ' och ' + m + ' ' + mt;
      }
    }
    if (lan==='en') {
      return diffMonths > 1 ? diffMonths + ' months' : diffMonths + ' month';
    } else {
      return diffMonths > 1 ? diffMonths + ' månader' : diffMonths + ' månad';
    }
  }
}
/**
 * 
 * @param {Float} coordinate 
 * @returns DMS string
 */
const toDegreesMinutesAndSeconds = (coordinate) => {
  var absolute = Math.abs(coordinate);
  var degrees = Math.floor(absolute);
  var minutesNotTruncated = (absolute - degrees) * 60;
  var minutes = Math.floor(minutesNotTruncated);
  var seconds = Math.floor((minutesNotTruncated - minutes) * 60);

  return degrees + "°" + minutes + "'" + seconds + '"';
}

/**
 * 
 * @param {Float} lat 
 * @param {Float} lng 
 * @returns String for DMS with their respective cardinals.
 */
export const convertDMS = (lat, lng) => {
  if (isNaN(lat)){
    return 'Unknown'
  }
  var latitude = toDegreesMinutesAndSeconds(lat);
  var latitudeCardinal = lat >= 0 ? "N" : "S";

  var longitude = toDegreesMinutesAndSeconds(lng);
  var longitudeCardinal = lng >= 0 ? "E" : "W";

  return latitude + "" + latitudeCardinal + " " + longitude + "" + longitudeCardinal;
}

/**
  * @param {String} str
  * @returns Array
  */
export const stringToArray = (str) => {
  if (typeof str === 'string') {
    let b = str.split(',');
    str = b.map((c) => c.trim());
  }
  return str
}

/**
  * Function that scrolls smoothly to a location on a page.
  * @param {String} ele
  * @returns 
  */
export const scroller = (ele) => {
  let offsetTop = document.getElementById(ele).offsetTop;
  window.scrollTo({
    top: offsetTop - 50,
    behavior: "smooth"
  });
}

export const toDate = (d) => {
  return Date.parse(d)
}
