import React from 'react'
import { convertDMS, monthDiff } from '../../app/helperFunctions';
import { ModalTable } from './ModalElements'

const InfoModalEmployments = ({d, l, lan}) => {

  const start = new Date(Date.parse(d.startdate));
  const end = new Date(Date.parse(d.enddate));
  const months = monthDiff(start, end, lan);
  const latlong = convertDMS(d.coords[0], d.coords[1]);

  return (
    <>
    <ModalTable>
        <tbody>
          <tr>
            <td>{l ? 'Oranization number:':'Organisationsnummer'}</td><td>{d.regno}</td>
          </tr>
          <tr>
            <td>{l ? 'Start - End:':'Start - Slut:'}</td><td>{d.startdate} - {d.enddate} {l ? '(duration: ':'(varaktighet: '}{months}.)</td>
          </tr>
          <tr>
            <td>{l ? 'Location: ':'Plats:'}:</td><td>{latlong}, Google short: {d.location}</td>
          </tr>
        </tbody>
    </ModalTable>
    </>
  )
}

export default InfoModalEmployments
