import React from "react";
import { FaTrash } from "react-icons/fa";
import {ModalMainLanSpec, ModalMainCommon } from '../AddModal/AddModalElements'
import {ModalTop} from './EditModalElements.js'

const EditModalEducationCourseSubPart = ({cid, scid, inputHandler, sp, deleteConfirmation }) => {

  return (
    <>
    <ModalMainLanSpec>
    <ModalTop><span>Sub-Part {cid+1}:{scid+1}</span><span><FaTrash title='Delete course sub part' onClick={() => deleteConfirmation('subpart', cid, scid)} /></span></ModalTop>
      <table>
        <thead>
          <tr>
            <th>Property</th><th>Content</th><th>Egenskap</th><th>Innehåll</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <label htmlFor={'subcoursename' + cid}>Sub Course name:</label>
            </td>
            <td>
              <input title='' type='text' id={'subcoursename' + cid} name={`course[${cid}].subparts[${scid}].name.en`} value={sp.name.en} onChange={(e) => inputHandler(e)} />
            </td>
            <td>
              <label htmlFor={'subcoursenamesv' + cid}>Kursnamn:</label>
            </td>
            <td>
              <input title='' type='text' id={'subcoursenamesv' + cid} name={`course[${cid}].subparts[${scid}].name.sv`} value={sp.name.sv} onChange={(e) => inputHandler(e)} />
            </td>
          </tr>
    {sp.description &&
          <tr>
            <td>
              <label htmlFor={'subdescription' + cid}>Description:</label>
            </td>
            <td>
              <textarea rows='4' id={'subdescription' + cid} name={`course[${cid}].subparts[${scid}].description.en`} value={sp.description.en || ''} onChange={(e) => inputHandler(e)} />
            </td>
            <td>
              <label htmlFor={'subdescriptionsv' + cid}>Beskrivning:</label>
            </td>
            <td>
              <textarea rows='4' id={'subdescriptionsv' + cid} name={`course[${cid}].subparts[${scid}].description.sv`} value={sp.description.sv || ''} onChange={(e) => inputHandler(e)} />
            </td>
          </tr>}
        </tbody>
      </table>
    </ModalMainLanSpec>
      <ModalMainCommon>
        <label htmlFor={'subcoursecode' + cid}>Sub Course Code:</label>
        <input type='text' id={'subcoursecode' + cid} name={`course[${cid}].subparts[${scid}].code`} value={sp.code} onChange={(e) => inputHandler(e)} />
        <label htmlFor={'subcoursecredits' + cid}>Sub Course Credits:</label>
        <input type='text' id={'coursecredits' + cid} name={`course[${cid}].subparts[${scid}].credits`} value={sp.credits} onChange={(e) => inputHandler(e)} />
        <label htmlFor={'subcoursegrade' + cid}>Sub Course Grade:</label>
        <input type='text' id={'subcoursegrade' + cid} name={`course[${cid}].subparts[${scid}].grade`} value={sp.grade} onChange={(e) => inputHandler(e)} />
        <label htmlFor={'subcoursedate' + cid}>Sub Course Finish Date:</label>
        <input type='date' id={'subcoursedate' + cid} name={`course[${cid}].subparts[${scid}].date`} value={sp.date} onChange={(e) => inputHandler(e)} />
      </ModalMainCommon>
    </>
  )
}

export default EditModalEducationCourseSubPart

