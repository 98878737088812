export const FONT_STYLE = '"Cambria", sans-serif';
export const FONTSIZE_1 = '24px';
export const FONTSIZE_2 = '16px';
export const FONTSIZE_3 = '9px';
export const FONT_REM_1 = '0.6rem';
export const FONT_REM_2 = '0.8rem';
export const FONT_REM_3 = '1.0rem';
export const FONT_REM_4 = '1.2rem';
export const FONT_REM_5 = '1.4rem';
export const COLOR_BRIGHT_MAIN = '#fffff2';
export const COLOR_DARK_MAIN = '#1e1e1e';
export const COLOR_BRIGHT_FONT = '#000';
export const COLOR_DARK_FONT = '#fff';
export const COLOR_BRIGHT_SECONDARY = '#9d9d9d';
export const COLOR_DARK_SECONDARY = '#5d5d5d';
export const MAIN_OFFSET = '40px';
export const MAIN_MID_OFFSET = '30px';
export const MID_MAIN_OFFSET = '20px';
export const MINOR_OFFSET = '10px';
export const SCREEN_MAX_WIDTH = '1048px';
