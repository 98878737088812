import React from 'react'
import { convertDMS, monthDiff, stringToArray } from '../../app/helperFunctions';
import { ModalTable } from './ModalElements'

const InfoModalAssignments = ({d, l, lan}) => {

  const roles = stringToArray(d.roles[lan]);
  const tasks = stringToArray(d.tasks[lan]);
  const skills = stringToArray(d.softskills[lan]);
  const tools = stringToArray(d.tools);
  const programming = stringToArray(d.programming);

  const start = new Date(Date.parse(d.startdate));
  const end = new Date(Date.parse(d.enddate));

  const months = monthDiff(start, end, lan);

  const latlong = convertDMS(d.coords[0], d.coords[1]);

  return (
    <>
    <ModalTable>
      <tbody>
        <tr>
          <td>{l ? 'Assignment ID:':'Uppdrags-ID:'}</td><td>{d.assignmentid}</td>
        </tr>
        <tr>
          <td>{l ? 'Client:':'Beställare:'}</td><td>{d.client}</td>
        </tr>
        <tr>
          <td>{l ? 'Organizational number:':'Organisationsnummer:'}</td><td>{d.regno}</td>
        </tr>
        <tr>
          <td>{l ? 'Role(s):':'Roll(er):'}</td><td>{<ul>{roles.map((role, i) => (<li key={i}>{role}</li>))}</ul>}</td>
        </tr>
        <tr>
          <td>{l ? 'Description:':'Beskrivning:'}</td><td>{!d.descriptionlong[lan] ? d.descriptionshort[lan] : d.descriptionlong[lan]}</td>
        </tr>
        <tr>
          <td>{l ? 'Tasks:':'Uppgifter:'}</td><td><ul>{tasks.map((task, i) => (<li key={i}>{task}</li>))}</ul></td>
        </tr>
        <tr>
          <td>{l ? 'Soft skills:':'Mjuka egenskaper:'}</td><td><ul>{skills.map((skill, i) => (<li key={i}>{skill}</li>))}</ul></td>
        </tr>
        <tr>
          <td>{l ? 'Tools:':'Verktyg:'}</td><td><ul>{tools.map((tool, i) => (<li key={i}>{tool}</li>))}</ul></td>
        </tr>
        <tr>
          <td>{l ? 'Programming:':'Programmering:'}</td><td><ul>{programming.map((program, i) => (<li key={i}>{program}</li>))}</ul></td>
        </tr>
        <tr>
          <td>{l ? 'Start - End:':'Start - Slut:'}</td><td>{d.startdate} - {d.enddate} {l ? '(duration: ':'(varaktighet: '} {months})</td>
        </tr>
        <tr>
          <td>{l ? 'Location:':'Plats:'}</td><td>{latlong}, Google short: {d.location}</td>
        </tr>
      </tbody>
    </ModalTable>
    </>
  )
}

export default InfoModalAssignments
