import styled from 'styled-components'

export const ModalAddTwoCol = styled.div`
`

export const ModalMain = styled.div`
  display: flex;
  flex-flow: column nowrap;
`

export const ModalMainLanSpec = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  div, label, input {
    font-size: 0.6rem;
  }
  div {
    font-weight: bold;
  }
  label {
    font-weight: normal;
  }
  th {
    font-weight: bold;
    font-size: 0.6rem;
  }
  td {
    font-weight: normal;
    font-size: 0.6rem;
  }
  textarea {
    box-sizing: border-box;
    width: 100%;
    font-size: 0.6rem;
  }
`

export const ModalMainLanEng = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  div, label, input, textarea {
    font-size: 0.6rem;
  }
`

export const ModalMainLanSwe = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  div, label, input, textarea {
    font-size: 0.6rem;
  }
`

export const ModalMainCommon = styled.div`
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  label, input, textarea {
    font-size: 0.6rem;
  }
  textarea {
    box-sizing: border-box;
    width: 100%;
  }
`

export const ModalSubTitle = styled.h3`
  margin: 0 50% 0 auto;
  transform: translateX(50%);
  font-size: 0.7rem;
`
export const ModalAddCourse = styled.div`

`

export const ModalAddCourseSubPart = styled.div`

`

export const ModalCourseCommon = styled.div`
`

export const ModalCourseSubPart = styled.div`
`

export const ModalSubCourseCommon = styled.div`
`
