import React, {useState, useEffect} from "react"
import {MobileNavMenu, MobileNavMenuClose} from './MobileNavMenuElements'
import {FaChevronDown} from 'react-icons/fa';

const MobileNavbar = ({l, scroller, toggle}) => {

  const navtoggle = (section) => {
    scroller(section);
    toggle();
  }

  return (
    <>
      <MobileNavMenu>
      <MobileNavMenuClose onClick={() => toggle()}>
        <FaChevronDown/>
      </MobileNavMenuClose>
        <ul>
          <li onClick={() => navtoggle("assignment")}>{l ? 'assignments':'uppdrag'}</li>
          <li onClick={() => navtoggle("employment")}>{l ? 'employments':'anställningar'}</li>
          <li onClick={() => navtoggle("education")}>{l ? 'education':'utbildning'}</li>
          <li onClick={() => navtoggle("certificate")}>{l ? 'certificate':'certifikat'}</li>
        </ul>
      </MobileNavMenu>
    </>
  )
}

export default MobileNavbar
