import React from 'react'
import { convertDMS } from '../../app/helperFunctions';
import { ModalTable } from './ModalElements'

const InfoModalCertificates = ({d, l, lan}) => {

  const latlong = convertDMS(d.coords[0], d.coords[1]);

  return (
    <>
    <ModalTable>
      <tbody>
        <tr>
          <td>{l ? 'Certificate ID:':'Certifikat-ID'}</td><td>{d.certificateid}</td>
        </tr>
        <tr>
          <td>{l ? 'Issuer:':'Utfärdare'}</td><td>{d.issuer}</td>
        </tr>
        <tr>
          <td>{l ? 'Description:':'Beskrivning:'}</td><td>{d.description[lan]}</td>
        </tr>
        <tr>
          <td>{l ? 'Date:':'Datum'}</td><td>{d.date}</td>
        </tr>
        <tr>
          <td>{l ? 'Location:':'Plats'}</td><td>{latlong}, Google short: {d.location}</td>
        </tr>
      </tbody>
    </ModalTable>
    </>
  )
}

export default InfoModalCertificates
