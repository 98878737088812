import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const NavbarContainer = styled.nav`
  background-color: #5d5d5d;
  display: flex;
  width: 100%;
  flex-flow: row nowrap;
  justify-content: center;
  padding: 0px 5px;
  color: #fff;
  position: fixed;
  top: 0px;
  z-index: 999;

  @media print {
    background-color: #fff;
    color: #000;
  }
`;

export const NavbarItem = styled(NavLink)`
  padding: 0px 15px;
  text-decoration: none;
  &:link {
    color: white;
  }
  &:visited {
    color: white;
    border-bottom: 1px solid white;
  }
  &:focus {
    color: white;
    border-bottom: 1px solid white;
  }
  &:hover {
    color: #9d9d9d;
  }

  @media print {
    display: none;
  }
`;

export const NavLan = styled.div`
  padding: 0px 15px;
  text-decoration: none;
  &:link {
    color: white;
  }
  &:visited {
    color: white;
    border-bottom: 1px solid white;
  }
  &:focus {
    color: white;
    border-bottom: 1px solid white;
  }
  &:hover {
    color: #9d9d9d;
  }
  cursor: pointer;

  @media print {
    display: none;
  }
`;

export const NavBarPrintContainer = styled.div`
  display: none;

  @media print {
    display: block;
    margin: 0 10%;
  }
`;
