import React, { useState } from "react";
import {
  ModalEditForm,
  ModalEditWrapper,
} from "../EditModal/EditModalElements";
import {
  ModalMain,
  ModalMainLanSpec,
  ModalMainCommon,
  ModalSubTitle,
} from "./AddModalElements";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { stringToArray } from "../../app/helperFunctions";
import reqConfig from "../../app/vars";
import makeToast from "../../pages/Toaster";

const AddModalEmployment = ({
  lastid,
  assignments,
  employments,
  setEmployments,
  closeModal,
}) => {
  const token = localStorage.getItem("accessToken");

  const [emp, setEmp] = useState({
    company: "",
    regno: "",
    location: "",
    coords: [],
    startdate: "",
    endadate: "",
    title: {
      en: "",
      sv: "",
    },
    assignmentid: [],
    assignmentnames: [],
  });

  const inputHandler = (e) => {
    let empCopy = { ...emp };
    let tmp = e.target.name;
    let tmp2 = tmp.split(".");
    if (tmp2[tmp2.length - 1] === "en" || tmp2[tmp2.length - 1] === "sv") {
      empCopy[tmp2[0]][tmp2[1]] = e.target.value;
    } else if (tmp === "lat") {
      empCopy["coords"][0] = Number(e.target.value);
    } else if (tmp === "long") {
      empCopy["coords"][1] = Number(e.target.value);
    } else {
      empCopy[tmp2[0]] = e.target.value;
    }
    setEmp(empCopy);
  };

  const modifyData = () => {
    let empCopy = { ...emp };
    let empMainCopy = [...employments];
    empCopy.title.en = stringToArray(empCopy.title.en);
    empCopy.title.sv = stringToArray(empCopy.title.sv);
    empCopy.assignmentid = stringToArray(empCopy.assignmentid);
    empCopy.assignmentid.map((assignment) => {
      return Number(assignment);
    });
    var asslist = empCopy.assignmentid;
    for (let i = 0; i < asslist.length; i++) {
      var aid = asslist[i];
      for (let j = 0; j < assignments.length; j++) {
        if (aid === assignments[j].assignmentid) {
          empCopy.assignmentnames.push(assignments[j].name.en);
          break;
        }
      }
    }
    setEmp(empCopy);
    empMainCopy.push(empCopy);
    setEmployments(empMainCopy);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    modifyData();
    updateEmployment();
    closeModal();
  };

  const updateEmployment = () => {
    const uA = async () => {
      await axios
        .post("/api/v1/dashboard/edit/employment", emp, reqConfig(token))
        .then((response) => {
          makeToast("success", "Added assignment successfully");
        })
        .catch((err) => {
          makeToast("error", err.data.msg);
          let empMainCopy = [...employments];
          setEmployments(empMainCopy);
        });
    };

    uA();
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Create employment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ModalEditWrapper>
          <ModalEditForm onSubmit={(e) => handleOnSubmit(e)}>
            <ModalMain>
              <ModalSubTitle>Language Specific</ModalSubTitle>
              <ModalMainLanSpec>
                <table>
                  <thead>
                    <tr>
                      <th>Property</th>
                      <th>Content</th>
                      <th>Egenskap</th>
                      <th>Innehåll</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <label htmlFor={"title"}>Title:</label>
                      </td>
                      <td>
                        <input
                          title="Add multiple titles by seperating them with ,"
                          type="text"
                          id={"title"}
                          name="title.en"
                          onChange={(e) => inputHandler(e)}
                        />
                      </td>
                      <td>
                        <label htmlFor={"titlesv"}>Titel:</label>
                      </td>
                      <td>
                        <input
                          title="Add multiple titles by seperating them with ,"
                          type="text"
                          id={"titlesv"}
                          name="title.sv"
                          onChange={(e) => inputHandler(e)}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ModalMainLanSpec>
              <ModalSubTitle>Non language specific</ModalSubTitle>
              <ModalMainCommon>
                <label htmlFor="company">Company</label>
                <input
                  type="text"
                  id="company"
                  name="company"
                  onChange={(e) => inputHandler(e)}
                />
                <label htmlFor="regno">Company reg. number</label>
                <input
                  type="text"
                  id="regno"
                  name="regno"
                  onChange={(e) => inputHandler(e)}
                />
                <label htmlFor="location">Google Location</label>
                <input
                  type="text"
                  id="location"
                  name="location"
                  onChange={(e) => inputHandler(e)}
                />
                <label htmlFor="lat">Latitude</label>
                <input
                  title="Decimals are seperated by dot (.)."
                  type="text"
                  id="lat"
                  name="lat"
                  onChange={(e) => inputHandler(e)}
                />
                <label htmlFor="long">Longitude</label>
                <input
                  title="Decimals are seperated by dot (.)."
                  type="text"
                  id="long"
                  name="long"
                  onChange={(e) => inputHandler(e)}
                />
                <label htmlFor="long">Start date</label>
                <input
                  title="ISO standard (YYYY-MM-DD)."
                  type="date"
                  id="startdate"
                  name="startdate"
                  onChange={(e) => inputHandler(e)}
                />
                <label htmlFor="long">End date</label>
                <input
                  title="ISO standard (YYYY-MM-DD)."
                  type="date"
                  id="enddate"
                  name="enddate"
                  onChange={(e) => inputHandler(e)}
                />
                <label htmlFor="assignmentids">Associated assigments</label>
                <input
                  title="Seperate each ID with ,"
                  type="text"
                  id="assignmentids"
                  name="assignmentid"
                  onChange={(e) => inputHandler(e)}
                />
              </ModalMainCommon>
            </ModalMain>
            <input type="submit" />
          </ModalEditForm>
        </ModalEditWrapper>
      </Modal.Body>
    </>
  );
};

export default AddModalEmployment;
