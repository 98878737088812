import { React, useState } from 'react'
import InfoModal from '../InfoModal';
import Modal from 'react-bootstrap/Modal';
import {FaEdit} from 'react-icons/fa'
import EditModal from '../EditModal/';
import {monthDiff } from '../../app/helperFunctions';
import { CommonContainer, CommonDates, CommonDuration, CommonEdit, CommonEndDate, CommonLeft, CommonLocation, CommonMiddle, CommonPlace, CommonRole, CommonStartDate, CommonWrapper, CommonMarginFiller, CommonMidTitle, CommonList } from '../CommonElements/CommonElements';

const Employments = ({data, setEmployments, employments, id, isLoggedIn, l, lan}) => {

  const company = data.company;
  const start = new Date(data.startdate.split('-'));
  const end = data.enddate == null ? '': new Date(data.enddate.split('-'));
  const title = data.title[lan][0];
  const assignmentnames = data.assignmentname[lan]
  const location = data.location;
  const months = monthDiff(start, end, lan);

  const [modal, setModal] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const openModal = () => {
    setModal(true);
  }

  const closeModal = () => {
    setModal(false);
  }

  var textRelatedAss = l ? 'Related assignments':'Relaterade uppdrag';

  return (
    <>
      <CommonContainer
        onMouseEnter={() => isLoggedIn ? setShowEdit(true) : setShowEdit(false)}
        onMouseLeave={() => setShowEdit(false)}
      >
        <CommonWrapper>
          <CommonLeft onClick={() => {
            setInfoModal(true);
            setEditModal(false);
            openModal();
          }}>
            <CommonRole>{title}</CommonRole>
            <CommonPlace>{company}</CommonPlace>
            <CommonLocation>{location.split(' ').slice(1)}</CommonLocation>
            <CommonDates>
                <CommonStartDate>
                  { l && (
                    start.toLocaleString('en-EN', {month: 'short', year:'numeric'})
                  )}
                  { !l && (
                    start.toLocaleString('sv-SE', {month: 'short', year:'numeric'})
                  )}
                </CommonStartDate>
                 - 
                <CommonEndDate>
                  { l && (
                    end.toLocaleString('en-EN', {month: 'short', year:'numeric'})
                  )}
                  { !l && (
                    end.toLocaleString('sv-SE', {month: 'short', year:'numeric'})
                  )}
                 </CommonEndDate>
              <CommonDuration>{months}</CommonDuration>
            </CommonDates>
            {assignmentnames.length <= 0 && (
              <CommonMarginFiller/>
            )}
          </CommonLeft>
          {assignmentnames.length > 0 && (
            <CommonMiddle onClick={() => {
              setInfoModal(true);
              setEditModal(false);
              openModal();
            }}>
            <CommonMidTitle>{!assignmentnames ? undefined : assignmentnames.length > 0 ? textRelatedAss : undefined}</CommonMidTitle>
            <CommonList><ul>{!assignmentnames ? undefined : assignmentnames.length > 0 ? assignmentnames.map((o, i) => (<li key={i}>{o}</li>)) : undefined}</ul></CommonList>
            </CommonMiddle>
          )}
          {showEdit && (
            <CommonEdit>
              <FaEdit onClick={() => {
                setEditModal(true);
                setInfoModal(false);
                openModal();
              }} />
            </CommonEdit>
          )}
        </CommonWrapper>
    <Modal
    size='lg'
    show={modal}
    onHide={() => setModal(false)}>
          {editModal && (
            <EditModal 
            type='employment' 
            d={data} 
            id={id} 
            data={employments} 
            setEmployments={setEmployments}
            closeModal={closeModal}
            />
          )}
          {infoModal && (
            <InfoModal type='employment' d={data} topline={data.company} l={l} lan={lan} />
          )}
    </Modal>
      </CommonContainer>
    </>
  )
}

export default Employments
