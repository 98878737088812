import styled from 'styled-components'

export const RegisterContainer = styled.div`
  background: #0e0d14;
`

export const RegisterWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  height: 100vh;
  padding: 50px 50px;
`

export const RegisterTopline = styled.h1`
  color: #01bf71;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
`

export const RegisterSection = styled.div`
  color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
`

export const RegisterForm = styled.form`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  row-gap: 0;
`

export const RegisterFormInput = styled.div`
  width: 100%;
  padding: 10px 0;
  display: grid;
  grid-template-columns: 1fr;
`

export const RegisterButton = styled.button`
  background: #41444a;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
  width: 100%;
  text-transform: uppercase;
  color: #ddd;
  font-weight: bolder;
  transition: all 0.2s;
`