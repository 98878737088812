import React, {useState, useEffect} from "react"
import axios from 'axios'
import reqConfig from "../../app/vars"
import { DashboardContainer, DashboardWrapper, DashboardSection, DashboardSectionTitle, DashboardItem, DashboardNavContainer, DashboardNavWrapper, DashboardAddItem, DashboardTitleText, NavMobileIcon } from "./DashboardElements"
import Assignments from '../Assignments'
import Employments from "../Employment"
import Educations from "../Education"
import Certificates from "../Certificate"
import Modal from "react-bootstrap/Modal";
import AddModal from "../AddModal"
import {FaPlus, FaBars} from 'react-icons/fa';
import {scroller} from '../../app/helperFunctions.js'
import MobileNavbar from "../MobileNavbar"
import { CommonDistance } from "../CommonElements/CommonElements"

const Dashboard = ({isLoggedIn, lan, l}) => {

  const [assignments, setAssignments] = useState([]);
  const [employments, setEmployments] = useState([]);
  const [educations, setEducations] = useState([]);
  const [certificates, setCertificates] = useState([]);

  const token = localStorage.getItem('accessToken');


  useEffect(() => {
    if (assignments.length === 0 || employments.length === 0 || educations.length === 0 || certificates.length === 0) {
      const getAssignments = async () => {
        // Get assignments
        const res1 = await axios.get('/api/v1/dashboard/assignment', reqConfig(token))
        const r1 = [...res1.data.entries];
        r1.sort((a,b) => b.assignmentid - a.assignmentid);
        // Get employments
        const res2 = await axios.get('/api/v1/dashboard/employment', reqConfig(token))
        const r2 = [...res2.data.entries];
        r2.sort((a,b) => b.employmentid - a.employmentid);
        // Get educations
        const res4 = await axios.get('/api/v1/dashboard/education', reqConfig(token))
        const r4 = [...res4.data.entries];
        r4.sort((a,b) => b.educationid - a.educationid);
        // Get certificates
        const res5 = await axios.get('/api/v1/dashboard/certificate', reqConfig(token))
        const r5 = [...res5.data.entries];
        r5.sort((a,b) => b.certificateid - a.certificateid);

        // Code to relate assignmentids refered to inside employment documents with their actual assignment names.
          var assinempen = {} // create empy object to store assignmentnames relating to each the employment, if any.
          var assinempsv = {}
          for (let i = 0; i < r2.length; i++){ // loop through the main array of employments
            var asslist = r2[i].assignmentid; // save array of assignments from employments into variable
            var eid = r2[i].employmentid; // store the employmentID in a variable
            assinempen[eid] = [] // Create an empty array for the current employmentID inside the Object
            assinempsv[eid] = []
            for (let j = 0; j < asslist.length; j++){ // loop through the array of assignments from the current employmentID
              var aid = asslist[j] // store the current assignmentID into a variable
              for (let k = 0; k < r1.length; k++){ // loop through the main array of assignments
                if (aid === r1[k].assignmentid) { // if the stored assignmentID matches the current assignmentID, store the current assignment's name in Object for the current employmentID
                  assinempen[eid].push(r1[k].name.en);
                  assinempsv[eid].push(r1[k].name.sv);
                  break;
                }
              }
            }
          }
        // Now place this object with arrays into the main employment
        const r3 = r2.map((o, i) => {
          var eid = r2[i].employmentid
          var namesen = assinempen[eid]
          var namessv = assinempsv[eid]
          return ({...o, assignmentname: {'en': namesen, 'sv': namessv}})
        })

        setAssignments(r1);
        setEmployments(r3);
        setEducations(r4);
        setCertificates(r5);

      }
      getAssignments()
    }
  }, []);


  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [showAddAss, setShowAddAss] = useState(false);
  const [showAddEmp, setShowAddEmp] = useState(false);
  const [showAddEdu, setShowAddEdu] = useState(false);
  const [showAddCert, setShowAddCert] = useState(false);
  const [addAssModal, setAddAssModal] = useState(false);
  const [addEmpModal, setAddEmpModal] = useState(false);
  const [addEduModal, setAddEduModal] = useState(false);
  const [addCertModal, setAddCertModal] = useState(false);

  const openModal = () => {
    setModal(true);
  }

  const closeModal = () => {
    setModal(false)
  }

  const toggle = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  }

  return (
    <>
      <DashboardContainer>
      <NavMobileIcon onClick={toggle}><FaBars/></NavMobileIcon>
        <DashboardNavContainer>
          <DashboardNavWrapper>
            <ul>
              <li onClick={() => scroller("assignment")}>{l ? 'assignments':'uppdrag'}</li>
              <li onClick={() => scroller("employment")}>{l ? 'employments':'anställningar'}</li>
              <li onClick={() => scroller("education")}>{l ? 'education':'utbildning'}</li>
              <li onClick={() => scroller("certificate")}>{l ? 'certificate':'certifikat'}</li>
            </ul>
          </DashboardNavWrapper>
        </DashboardNavContainer>
        <DashboardWrapper>
          <DashboardSection>
            <CommonDistance/>
            <DashboardItem>
              <DashboardSectionTitle
              id="assignment"
              onMouseEnter={() => isLoggedIn ? setShowAddAss(true) : setShowAddAss(false)}
              onMouseLeave={() => setShowAddAss(false)}
              >
              <DashboardTitleText>{l ? 'Assignments':'Uppdrag'}</DashboardTitleText>
              {showAddAss && (
                <DashboardAddItem>
                <FaPlus onClick={() => {
                  setAddAssModal(true);
                  setAddEmpModal(false);
                  setAddEduModal(false);
                  setAddCertModal(false);
                  openModal();
                }} />
                </DashboardAddItem>
              )}
              </DashboardSectionTitle>
              {assignments.map((assignment, i) => (<Assignments key={i} id={i} data={assignment} setAssignments={setAssignments} assignments={assignments} isLoggedIn={isLoggedIn} l={l} lan={lan} />))}
            </DashboardItem>
          </DashboardSection>
          <DashboardSection>
            <DashboardItem>
              <DashboardSectionTitle
              id="employment"
              onMouseEnter={() => isLoggedIn ? setShowAddEmp(true): setShowAddEmp(false)}
              onMouseLeave={() => setShowAddEmp(false)}
              >
              <DashboardTitleText>{l ? 'Employment':'Anställningar'}</DashboardTitleText>
              {showAddEmp && (
                <DashboardAddItem>
                <FaPlus onClick={() => {
                  setAddAssModal(false);
                  setAddEmpModal(true);
                  setAddEduModal(false);
                  setAddCertModal(false);
                  openModal();
                }} />
                </DashboardAddItem>
              )}
              </DashboardSectionTitle>
              {employments.map((employment, i) => (<Employments key={i} id={i} data={employment} employments={employments} setEmployments={setEmployments} isLoggedIn={isLoggedIn} l={l} lan={lan} />))}
            </DashboardItem>
          </DashboardSection>
          <DashboardSection>
            <DashboardItem>
              <DashboardSectionTitle
              id="education"
              onMouseEnter={() => isLoggedIn ? setShowAddEdu(true): setShowAddEdu(false)}
              onMouseLeave={() => setShowAddEdu(false)}
              >
              <DashboardTitleText>{l ? 'Education':'Utbildning'}</DashboardTitleText>
              {showAddEdu && (
                <DashboardAddItem>
                <FaPlus onClick={() => {
                  setAddAssModal(false);
                  setAddEmpModal(false);
                  setAddEduModal(true);
                  setAddCertModal(false);
                  openModal();
                }} />
                </DashboardAddItem>
              )}
              </DashboardSectionTitle>
              {educations.map((education, i) => (<Educations key={i} id={i} data={education} educations={educations} setEducations={setEducations} isLoggedIn={isLoggedIn} l={l} lan={lan} />))}
            </DashboardItem>
          </DashboardSection>
          <DashboardSection>
            <DashboardItem>
              <DashboardSectionTitle
              id="certificate"
              onMouseEnter={() => isLoggedIn ? setShowAddCert(true): setShowAddCert(false)}
              onMouseLeave={() => setShowAddCert(false)}
              >
              <DashboardTitleText>{l ? 'Certificates':'Certifikat'}</DashboardTitleText>
              {showAddCert && (
                <DashboardAddItem>
                <FaPlus onClick={() => {
                  setAddAssModal(false);
                  setAddEmpModal(false);
                  setAddEduModal(false);
                  setAddCertModal(true);
                  openModal();
                }} />
                </DashboardAddItem>

              )}
              </DashboardSectionTitle>
              {certificates.map((certificate, i) => (<Certificates key={i} id={i} data={certificate} certificates={certificates} setCertificates={setCertificates}  isLoggedIn={isLoggedIn} l={l} lan={lan} />))}
            </DashboardItem>
          </DashboardSection>
        </DashboardWrapper>
        <Modal
          size="lg"
          show={modal}
          onHide={() => setModal(false)}
        >
          {addAssModal && (
            <AddModal type='assignment' lastid={assignments.length + 1} data={assignments} setData={setAssignments} closeModal={closeModal}  l={l} lan={lan}/>
          )}
          {addEmpModal && (
            <AddModal type='employment' lastid={employments.length + 1} data={employments} assignments={assignments} setData={setEmployments} closeModal={closeModal} l={l} lan={lan} />
          )}
          {addEduModal && (
            <AddModal type='education' lastid={educations.length + 1} data={educations} setData={setEducations} closeModal={closeModal} l={l} lan={lan} />
          )}
          {addCertModal && (
            <AddModal type='certificate' lastid={certificates.length + 1} data={certificates} setData={setCertificates} closeModal={closeModal} l={l} lan={lan} />
          )}
        </Modal>
      </DashboardContainer>
      {mobileMenuOpen && (
        <MobileNavbar l={l} scroller={scroller} toggle={toggle}/>
      )}
    </>
  )
}

export default Dashboard
