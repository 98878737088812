import {React, useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import InfoModal from '../InfoModal';
import { EducationProgramme, EducationDegree } from './EducationElements'
import {FaEdit} from 'react-icons/fa';
import EditModal from '../EditModal';
import {monthDiff } from '../../app/helperFunctions';
import { CommonContainer, CommonDates, CommonDuration, CommonEdit, CommonEndDate, CommonLeft, CommonMiddle, CommonRole, CommonStartDate, CommonWrapper, CommonList } from '../CommonElements/CommonElements';

const Educations = ({data, id, educations, setEducations, isLoggedIn, l, lan}) => {
  
  const school = data.school[lan]
  const start = new Date(data.startdate.split('-'))
  const end = new Date(data.enddate.split('-'))
  const courses = data.course
  const months = monthDiff(start, end, lan);
  
  const [modal, setModal] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const openModal = () => {
    setModal(true);
  }

  const closeModal = () => {
    setModal(false);
  }


  return (
    <>
      <CommonContainer
        onMouseEnter={() => isLoggedIn ? setShowEdit(true) : setShowEdit(false)}
        onMouseLeave={() => setShowEdit(false)}
      >
        <CommonWrapper onClick={() => openModal()}>
          <CommonLeft onClick={() => {
            setInfoModal(true);
            setEditModal(false);
            openModal();
          }}>
            <CommonRole>{school}</CommonRole>
            <CommonDates>
              <CommonStartDate>
                { l && (
                  start.toLocaleString('en-EN', {month: 'short', year:'numeric'})
                )}
                { !l && (
                  start.toLocaleString('sv-SE', {month: 'short', year:'numeric'})
                )}
              </CommonStartDate>
               - 
              <CommonEndDate>
                { l && (
                  end.toLocaleString('en-EN', {month: 'short', year:'numeric'})
                )}
                { !l && (
                  end.toLocaleString('sv-SE', {month: 'short', year:'numeric'})
                )}
               </CommonEndDate>
                <CommonDuration>{months}</CommonDuration>
            </CommonDates>
          </CommonLeft>
          <CommonMiddle onClick={() => {
            setInfoModal(true);
            setEditModal(false);
            openModal();
          }}>
            {data.programme ? (<EducationProgramme>{data.programme[lan]}</EducationProgramme>) : (<CommonList><ul>{
              courses.map((course, i) => (<li key={i}>{course.name[lan]}</li>))}</ul></CommonList>)}
            {data.degree ? (<EducationDegree>{data.degree[lan]}</EducationDegree>) : undefined}
            
          </CommonMiddle>
    {showEdit && (
      <CommonEdit>
      <FaEdit onClick={() => {
        setEditModal(true);
        setInfoModal(false);
        openModal();
      }} />
      </CommonEdit>
    )}
        </CommonWrapper>
    <Modal
    size="lg"
    show={modal}
    onHide={() => setModal(false)}
    >
    {editModal && (
            <EditModal
              type='education'
              d={data} id={id}
              data={educations}
              setEducations={setEducations}
              closeModal={closeModal}
              openModal={openModal}
            />
    )}
    {infoModal && (
      <InfoModal type='education' d={data} topline={data.programme ? data.programme[lan] : data.school[lan]} l={l} lan={lan}/>
    )}
    </Modal>
      </CommonContainer>
    </>
  )
}

export default Educations
